import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid'
import {Link as WHSLink} from 'react-router-dom'
import {GlobalStateContext} from '../../components/global-state'
import {useHistory, useLocation} from 'react-router-dom'

const TopContent = ({
    categories: {categories},
    selectedCategory,
    selectedCategoryId,
    categoryName,
    queryString,
    goToHref,
    setLoading
}) => {
    const [cats, setCats] = useState([])
    let showCatg
    const globalState = useContext(GlobalStateContext)
    const location = useLocation()
    const categoryProducts = globalState?.categoryProductData
    const history = useHistory()
    let query = ''

    if (location?.search) {
        query = new URLSearchParams(location.search)
        query.delete('p')
        query.delete('retry')
    }

    useEffect(() => {
        if (categories && categories.length > 0)
            setCats(
                categories.reduce((result, category) => {
                    category.c_showInMenu && result.push(category)
                    return result
                }, [])
            )
    }, [categories])

    const getProducts = (url) => {
        const searchProducts = categoryProducts?.[url]
        const getValue = Object.keys(searchProducts || {})?.filter(
            (el) => searchProducts[el].length
        )

        const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))
        if (maxValue) {
            return maxValue
        }
        return ''
    }

    useEffect(() => {
        if (document.getElementById('scrollerNav')) {
            document.getElementById('scrollerNav').addEventListener('touchmove', () => {
                $('.nav-scroller-nav').css({
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    transform: 'initial'
                })
                let firstCat = $('.nav-scroller-content a:first-child').offset()
                if (Math.round(firstCat.left) < 25) {
                    $('.nav-scroller-btn').addClass('active')
                }
                if (
                    Math.round(firstCat.left) ===
                    (checkIfTabResolution() ? -354 : checkIfMobileResolution() ? -773 : '')
                ) {
                    $('.nav-scroller-btn').removeClass('active')
                    $('.nav-scroller-btn--left').addClass('active')
                }
                if (
                    Math.round(firstCat.left) ===
                    (checkIfTabResolution() ? 36 : checkIfMobileResolution() ? 25 : '')
                ) {
                    //$('.nav-scroller-btn').css('opacity',1)
                    $('.nav-scroller-btn').removeClass('active')
                    $('.nav-scroller-btn--right').addClass('active')
                }
            })
        }
    }, [])

    /* const checkIfDesktop = () => {
        return window.innerWidth >= 1024
    } */

    const checkIfTabResolution = () => {
        return window.innerWidth >= 768 && window.innerWidth <= 1023
    }

    const checkIfMobileResolution = () => {
        return window.innerWidth <= 767
    }

    const scrollArrow = (pos) => {
        if (pos === 'right') {
            if (checkIfMobileResolution()) {
                let firstCat = $('.nav-scroller-content a:first-child').offset()
                let transformValue = '-265px'
                if (firstCat.left >= 0) transformValue = '-265px'
                else if (firstCat.left < 0 && firstCat.left >= -265) transformValue = '-530px'
                else if (firstCat.left < -265 && firstCat.left >= -530) transformValue = '-815px'
                $('.nav-scroller-nav').css({
                    // transform: `translateX(${'-784px'})`,
                    transform: `translateX(${transformValue})`,
                    overflowX: 'initial',
                    overflowY: 'initial',
                    transition: 'ease',
                    transitionDuration: '600ms'
                })
                if (Math.round(firstCat.left) < '25') {
                    $('.nav-scroller-btn').addClass('active')
                    //setShowCatg(null);
                }
                if (transformValue === '-265px' || transformValue === '-530px') {
                    if (!$('.nav-scroller-btn--left').hasClass('active'))
                        $('.nav-scroller-btn--left').addClass('active')
                } else {
                    $('.nav-scroller-btn').removeClass('active')
                    $('.nav-scroller-btn--left').addClass('active')
                }
            } else {
                $('.nav-scroller-nav').css({
                    transform: `translateX(${checkIfTabResolution() ? '-367px' : '-194px'})`,
                    overflowX: 'initial',
                    overflowY: 'initial',
                    transition: 'ease',
                    transitionDuration: '600ms'
                })
                let firstCat = $('.nav-scroller-content a:first-child').offset()
                if (Math.round(firstCat.left) < '25') {
                    $('.nav-scroller-btn').addClass('active')
                    //setShowCatg(null);
                }
                $('.nav-scroller-btn').removeClass('active')
                $('.nav-scroller-btn--left').addClass('active')
            }
        }
        if (pos === 'left') {
            if (checkIfMobileResolution()) {
                let firstCat = $('.nav-scroller-content a:first-child').offset()
                let transformValue = '0px'
                if (firstCat.left < 0 && firstCat.left >= -265) transformValue = '0px'
                else if (firstCat.left < -265 && firstCat.left >= -530) transformValue = '-265px'
                else if (firstCat.left < -530 && firstCat.left >= -815) transformValue = '-530px'
                $('.nav-scroller-nav').css({
                    // transform: `translateX(${'-784px'})`,
                    transform: `translateX(${transformValue})`,
                    overflowX: 'initial',
                    overflowY: 'initial',
                    transition: 'ease',
                    transitionDuration: '600ms'
                })
                if (transformValue === '-265px' || transformValue === '-530px') {
                    if (!$('.nav-scroller-btn--right').hasClass('active'))
                        $('.nav-scroller-btn--right').addClass('active')
                } else {
                    $('.nav-scroller-btn').removeClass('active')
                    $('.nav-scroller-btn--right').addClass('active')
                }
            } else {
                $('.nav-scroller-nav').css({
                    transform: `translateX(0px)`,
                    overflowX: 'initial',
                    overflowY: 'initial',
                    transition: 'ease',
                    transitionDuration: '600ms'
                })
                $('.nav-scroller-btn').removeClass('active')
                $('.nav-scroller-btn--right').addClass('active')
            }
        }
    }

    return (
        <div className="slp-top-content">
            <div className="content-slot slot-grid-header"></div>
            <div className="search-results">
                {queryString ? (
                    <>
                        Your search phrase was &quot;
                        <WHSLink
                            className="search-results-link"
                            to={goToHref({
                                pathname: `/search/`,
                                search: new URLSearchParams(
                                    `q=${queryString}&cgid=&category=All`
                                ).toString()
                            })}
                            onClick={(e) => {
                                e.preventDefault()
                                const href = goToHref({
                                    pathname: `/search/`,
                                    search: new URLSearchParams(
                                        `q=${queryString}&cgid=&category=All`
                                    ).toString()
                                })

                                const pageNumberTotal =
                                    getProducts(`${href.pathname}?${href.search}`) || 0

                                // setLoading(true)
                                history.push({
                                    pathname: href.pathname,
                                    search: `${href.search}${
                                        pageNumberTotal ? `&p=${pageNumberTotal}` : ''
                                    }`
                                })
                                // if (`?${href.search}` !== location.search  || (new URLSearchParams(location.search)).has('p')) {
                                //     // setLoading(true)
                                // }
                            }}
                            title={queryString}
                        >
                            {queryString}
                        </WHSLink>
                        &quot; -
                    </>
                ) : (
                    <></>
                )}{' '}
                {selectedCategory?.toUpperCase() === 'ALL' ? (
                    <span className="search-results__break">
                        Refine your results by selecting a category below
                    </span>
                ) : (
                    <span className="search-results__break">
                        Showing results for{' '}
                        {queryString ? (
                            <>
                                &quot;
                                <WHSLink
                                    className="search-results-link"
                                    to={goToHref({
                                        pathname: `/search/`,
                                        search: new URLSearchParams(
                                            `q=${queryString}&cgid=${selectedCategoryId}&category=${selectedCategory}`
                                        ).toString()
                                    })}
                                    onClick={(e) => {
                                        e.preventDefault()

                                        const href = goToHref({
                                            pathname: `/search/`,
                                            search: new URLSearchParams(
                                                `q=${queryString}&cgid=${selectedCategoryId}&category=${selectedCategory}`
                                            ).toString()
                                        })
                                        const pageNumberTotal =
                                            getProducts(`${href.pathname}?${href.search}`) || 0
                                        history.push({
                                            pathname: href.pathname,
                                            search: `${href.search}${
                                                pageNumberTotal ? `&p=${pageNumberTotal}` : ''
                                            }`
                                        })
                                    }}
                                    title={queryString}
                                >
                                    {queryString}
                                </WHSLink>
                                &quot;
                            </>
                        ) : (
                            <>{'all products'}</>
                        )}{' '}
                        in{' '}
                        <WHSLink
                            className="search-results-link"
                            to={goToHref({
                                pathname: `/search/`,
                                search: new URLSearchParams(
                                    `q=${queryString}&cgid=${selectedCategoryId}&category=${selectedCategory}`
                                ).toString()
                            })}
                            onClick={(e) => {
                                e.preventDefault()
                                const href = goToHref({
                                    pathname: `/search/`,
                                    search: new URLSearchParams(
                                        `q=${queryString}&cgid=${selectedCategoryId}&category=${selectedCategory}`
                                    ).toString()
                                })
                                const pageNumberTotal =
                                    getProducts(`${href.pathname}?${href.search}`) || 0
                                history.push({
                                    pathname: href.pathname,
                                    search: `${href.search}${
                                        pageNumberTotal ? `&p=${pageNumberTotal}` : ''
                                    }`
                                })
                            }}
                            title={`Go to ${selectedCategory}`}
                        >
                            {categoryName || selectedCategory}
                        </WHSLink>
                    </span>
                )}
            </div>
            {selectedCategory?.toLowerCase() === 'all' && (
                <div className="nav-scroller-wrap">
                    <div className="nav-scroller nav-scroller--snap">
                        <nav className="nav-scroller-nav" id="scrollerNav">
                            <div className="nav-scroller-content">
                                <WHSLink
                                    to={goToHref({
                                        pathname: `/search/`,
                                        search: new URLSearchParams(
                                            `q=${queryString}&cgid=&category=All`
                                        ).toString()
                                    })}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const href = goToHref({
                                            pathname: `/search/`,
                                            search: new URLSearchParams(
                                                `q=${queryString}&cgid=&category=All`
                                            ).toString()
                                        })
                                        const pageNumberTotal =
                                            getProducts(`${href.pathname}?${href.search}`) || 0
                                        history.push({
                                            pathname: href.pathname,
                                            search: `${href.search}${
                                                pageNumberTotal ? `&p=${pageNumberTotal}` : ''
                                            }`
                                        })
                                    }}
                                >
                                    All
                                </WHSLink>
                                {cats &&
                                    cats.length > 0 &&
                                    cats.map((category) => (
                                        <WHSLink
                                            key={`link-${category.id}`}
                                            to={goToHref({
                                                pathname: `/search/`,
                                                search: new URLSearchParams(
                                                    `q=${queryString}&cgid=${category.id.toUpperCase()}&category=${
                                                        category.name
                                                    }`
                                                ).toString()
                                            })}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                const href = goToHref({
                                                    pathname: `/search/`,
                                                    search: new URLSearchParams(
                                                        `q=${queryString}&cgid=${category.id.toUpperCase()}&category=${
                                                            category.name
                                                        }`
                                                    ).toString()
                                                })
                                                const pageNumberTotal =
                                                    getProducts(
                                                        `${href.pathname}?${href.search}`
                                                    ) || 0

                                                setLoading(true)
                                                history.push({
                                                    pathname: href.pathname,
                                                    search: `${href.search}${
                                                        pageNumberTotal
                                                            ? `&p=${pageNumberTotal}`
                                                            : ''
                                                    }`
                                                    // updatedQuery.toString()
                                                })
                                            }}
                                        >
                                            {category.name}
                                        </WHSLink>
                                    ))}
                            </div>
                        </nav>

                        {showCatg || showCatg == null ? (
                            <button
                                className="nav-scroller-btn nav-scroller-btn--left"
                                aria-label="Scroll left"
                                onClick={() => scrollArrow('left')}
                            >
                                <svg className="pagination-control-icon">
                                    <title>Slider left arrow</title>
                                    <use xlinkHref="#icon-arrow">
                                        <svg id={uuidv4() + 'icon-arrow'} viewBox="0 0 8 13.188">
                                            <path
                                                id={uuidv4() + 'ic_arrow'}
                                                className="cls-1"
                                                d="M1356.09 376.307l1.41-1.414 6.61 6.607-1.42 1.413zm6.6 3.778l1.42 1.415-6.61 6.606-1.41-1.414z"
                                                transform="translate(-1356.09 -374.906)"
                                            ></path>
                                        </svg>
                                    </use>
                                </svg>
                            </button>
                        ) : (
                            ''
                        )}

                        {!showCatg || showCatg == null ? (
                            <button
                                className="nav-scroller-btn nav-scroller-btn--right active"
                                aria-label="Scroll right"
                                onClick={() => scrollArrow('right')}
                            >
                                <svg className="pagination-control-icon">
                                    <title>Slider right arrow</title>
                                    <use xlinkHref="#icon-arrow">
                                        <svg id={uuidv4() + 'icon-arrow'} viewBox="0 0 8 13.188">
                                            <path
                                                id={uuidv4() + 'ic_arrow'}
                                                className="cls-1"
                                                d="M1356.09 376.307l1.41-1.414 6.61 6.607-1.42 1.413zm6.6 3.778l1.42 1.415-6.61 6.606-1.41-1.414z"
                                                transform="translate(-1356.09 -374.906)"
                                            ></path>
                                        </svg>
                                    </use>
                                </svg>
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

TopContent.propTypes = {
    searchPhrase: PropTypes.any,
    categories: PropTypes.array,
    selectedCategory: PropTypes.string,
    selectedCategoryId: PropTypes.string,
    queryString: PropTypes.string
}

export default TopContent
