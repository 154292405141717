import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'

import SidebarTemplate from './sidebarTemplate'
import PrimaryContent from './primaryContent'
import Breadcrumbs from './breadCrumb'
import {Desktop, TabletOrSmaller} from '../media-queries'

const ProductListPageTemplate = ({
    perPageItemsValue,
    paginationPage,
    category,
    categoryContentAssetId,
    categoryContentAssetId2,
    currentValue,
    data,
    errorMessage,
    isSearchListing,
    loaded,
    masterDataArray,
    productData,
    productsPerPageOptions,
    queryCategory,
    queryCategoryName,
    queryString,
    refinedItems,
    refinements,
    selectedSortedValue,
    setReFinedItems,
    showCharts,
    sortingOptions,
    onClearAll,
    pageDataPushed,
    setLoading,
    onApplyFilter,
    refinementLoading = false,
    totalProducts,
    oldUrl,
    setOldUrl,
    fetchLazyContent,
    goToHref,
    showWarningMessage,
    goToCanonicalLink,
    showSkeleton,
    pageNumber,
    showViewMore,
    apiTimedOut,
    handleTimeoutSkeleton
}) => {
    const [initiallyClosed, setInitiallyClosed] = useState([''])
    const location = useLocation()
    const history = useHistory()

    const removeFn = (event) => {
        event.preventDefault()
        showSideBar(false)
        history.replace({
            pathname: location.pathname,
            search: location.search
        })
    }

    const showSideBar = (value) => {
        document.getElementById('sub-secondary').classList.add('sidebar-refinements-section')
        if (value == false) {
            setTimeout(() => {
                refinements.forEach((item) => {
                    document.getElementById(`accordion__item-${item.attribute_id}`) &&
                        document
                            .getElementById(`accordion__item-${item.attribute_id}`)
                            .classList.contains('pw-accordion--is-open') &&
                        document
                            .querySelector(`#accordion__item-${item.attribute_id} button`)
                            .click()
                })
            }, 500)
        }
        if (value === true) {
            let url = new URLSearchParams(location.search)
            url.delete('start')
            url.delete('srule')
            url.delete('sz')
            setOldUrl(url.toString())
        } else {
            setOldUrl('')
        }
        if (value === false && document.querySelector('.refinement-title.expanded') !== null) {
            document.querySelector('.refinement-title.expanded').click()
            if (document.querySelector('.refinement-title.expanded') !== null) {
                document.querySelector('.refinement-title.expanded').classList.toggle('expanded')
            }
        }
        let item = document.getElementsByClassName('overlay')
        if (value) {
            item[0].classList.add('full-page-overlay')
            document.getElementById('primary').classList.add('active-refinements')
            item[0].addEventListener('touchstart', removeFn, false)
            item[0].addEventListener('click', removeFn, false)
        } else {
            item[0].classList.remove('full-page-overlay')
            document.getElementById('primary').classList.remove('active-refinements')

            item[0].removeEventListener('touchstart', removeFn, false)
            item[0].removeEventListener('click', removeFn, false)
        }
    }

    const clearAllRefine = (event) => {
        event.preventDefault()
        onClearAll(event)
        setInitial()
    }

    const setInitial = () => {
        if (document.getElementsByClassName('accordian-in-desktop').length > 0) {
            initiallyClosed.slice(1).forEach((item) => {
                document.getElementById(`accordion__item-${item}`) &&
                    document
                        .getElementById(`accordion__item-${item}`)
                        .classList.contains('pw-accordion--is-open') &&
                    document.querySelector(`#accordion__item-${item} button`).click()
            })
        }
    }

    const handleRefineRemove = (event) => {
        event.preventDefault()
        let exist = refinedItems.findIndex(
            (data) => data.name == event.target.parentNode.dataset.value
        )
        let temp = [...refinedItems]
        temp.splice(exist, 1)
        setReFinedItems([...temp])
        setLoading(true)
    }

    const applyFilter = (event) => {
        event.preventDefault()
        showSideBar(false)
        onApplyFilter()
    }

    const handleClose = (event) => {
        event.preventDefault()
        // setReFinedItems([...tempRefinedItems])
        let url = new URLSearchParams(location.search)
        url.delete('start')
        url.delete('srule')
        url.delete('sz')
        if (
            (oldUrl?.length && url.toString() !== oldUrl) ||
            (!oldUrl.length && url.toString().length)
        ) {
            // setLoading(true)
            history.replace({
                pathname: location.pathname,
                search: oldUrl
            })
        }
        showSideBar(false)
    }

    return (
        <div className="plp-page-container">
            <Desktop>
                {category && (
                    <Breadcrumbs
                        breadcrumb={category}
                        isSearchListing={isSearchListing}
                        goToHref={goToHref}
                        setLoading={setLoading}
                    />
                )}
            </Desktop>
            <div className="bg-container plp-template">
                <div
                    id="main"
                    role="main"
                    className="clearfix content-width content-holder-padding"
                >
                    <Desktop>
                        <div className="column-wrapper m-cmp_loaded" data-cmp="PLPNavigation">
                            <div id="leftSideBar" className="secondary-content">
                                <SidebarTemplate
                                    sidebarData={data}
                                    refinements={refinements}
                                    refinedItems={refinedItems}
                                    setReFinedItems={setReFinedItems}
                                    clearAllRefine={clearAllRefine}
                                    handleRefineRemove={handleRefineRemove}
                                    goToHref={goToHref}
                                    setLoading={setLoading}
                                    setInitiallyClosed={setInitiallyClosed}
                                    showCharts={showCharts}
                                    isSearchListing={isSearchListing}
                                    queryString={queryString}
                                    queryCategory={queryCategory}
                                    refinementLoading={refinementLoading}
                                    classNames={
                                        'product-list-page-sidebar js-refinements refinements content-scroll m-cmp_loaded'
                                    }
                                />
                            </div>
                            <PrimaryContent
                                showSideBar={showSideBar}
                                showWarningMessage={showWarningMessage}
                                goToCanonicalLink={goToCanonicalLink}
                                perPageItemsValue={perPageItemsValue}
                                paginationPage={paginationPage}
                                categoryContentAssetId={categoryContentAssetId}
                                categoryContentAssetId2={categoryContentAssetId2}
                                category={category}
                                errorMessage={errorMessage}
                                sortingOptions={sortingOptions}
                                currentValue={currentValue}
                                productsPerPageOptions={productsPerPageOptions}
                                refinedItems={refinedItems}
                                clearAllRefine={clearAllRefine}
                                handleRefineRemove={handleRefineRemove}
                                refinements={refinements}
                                goToHref={goToHref}
                                selectedSortedValue={selectedSortedValue}
                                productData={productData}
                                masterDataArray={masterDataArray}
                                loaded={loaded}
                                showCharts={showCharts}
                                isSearchListing={isSearchListing}
                                queryString={queryString}
                                queryCategory={queryCategory}
                                queryCategoryName={queryCategoryName}
                                fetchLazyContent={fetchLazyContent}
                                pageDataPushed={pageDataPushed}
                                setLoading={setLoading}
                                totalProducts={totalProducts}
                                showSkeleton={showSkeleton}
                                pageNumber={pageNumber}
                                showViewMore={showViewMore}
                                apiTimedOut={apiTimedOut}
                                handleTimeoutSkeleton={handleTimeoutSkeleton}
                            />
                        </div>
                    </Desktop>
                    <TabletOrSmaller>
                        <div className="column-wrapper m-cmp_loaded" data-cmp="PLPNavigation">
                            <div id="primary" className="primary-content is-reverse">
                                <SidebarTemplate
                                    sidebarData={data}
                                    refinements={refinements}
                                    closeButton={showSideBar}
                                    refinedItems={refinedItems}
                                    setReFinedItems={setReFinedItems}
                                    clearAllRefine={clearAllRefine}
                                    goToHref={goToHref}
                                    handleRefineRemove={handleRefineRemove}
                                    setLoading={setLoading}
                                    applyFilter={applyFilter}
                                    handleClose={handleClose}
                                    setInitiallyClosed={setInitiallyClosed}
                                    showCharts={showCharts}
                                    isSearchListing={isSearchListing}
                                    queryString={queryString}
                                    queryCategory={queryCategory}
                                    classNames={
                                        'product-list-page-sidebar js-refinements refinements secondary-content content-scroll m-cmp_loaded'
                                    }
                                />
                                <div className="content-slot slot-grid-header">
                                    <PrimaryContent
                                        perPageItemsValue={perPageItemsValue}
                                        showWarningMessage={showWarningMessage}
                                        goToCanonicalLink={goToCanonicalLink}
                                        paginationPage={paginationPage}
                                        categoryContentAssetId={categoryContentAssetId}
                                        categoryContentAssetId2={categoryContentAssetId2}
                                        category={category}
                                        errorMessage={errorMessage}
                                        sortingOptions={sortingOptions}
                                        currentValue={currentValue}
                                        productsPerPageOptions={productsPerPageOptions}
                                        refinedItems={refinedItems}
                                        clearAllRefine={clearAllRefine}
                                        handleRefineRemove={handleRefineRemove}
                                        refinements={refinements}
                                        goToHref={goToHref}
                                        selectedSortedValue={selectedSortedValue}
                                        showSideBar={showSideBar}
                                        productData={productData}
                                        masterDataArray={masterDataArray}
                                        loaded={loaded}
                                        showCharts={showCharts}
                                        isSearchListing={isSearchListing}
                                        queryString={queryString}
                                        fetchLazyContent={fetchLazyContent}
                                        queryCategory={queryCategory}
                                        queryCategoryName={queryCategoryName}
                                        pageDataPushed={pageDataPushed}
                                        setLoading={setLoading}
                                        totalProducts={totalProducts}
                                        showSkeleton={showSkeleton}
                                        pageNumber={pageNumber}
                                        showViewMore={showViewMore}
                                        apiTimedOut={apiTimedOut}
                                        handleTimeoutSkeleton={handleTimeoutSkeleton}
                                    />
                                </div>
                            </div>
                        </div>
                    </TabletOrSmaller>
                </div>
            </div>
        </div>
    )
}

ProductListPageTemplate.propTypes = {
    data: PropTypes.object,
    category: PropTypes.object,
    errorMessage: PropTypes.string,
    sortingOptions: PropTypes.array,
    currentValue: PropTypes.any,
    refinements: PropTypes.array,
    productsPerPageOptions: PropTypes.array,
    categoryContentAssetId: PropTypes.string,
    refinedItems: PropTypes.array,
    setReFinedItems: PropTypes.func,
    selectedSortedValue: PropTypes.string,

    productData: PropTypes.array,
    masterDataArray: PropTypes.array,
    loaded: PropTypes.bool,
    categoryContentAssetId2: PropTypes.string,
    showCharts: PropTypes.any,
    isSearchListing: PropTypes.bool,
    queryString: PropTypes.string,
    queryCategory: PropTypes.string,
    queryCategoryName: PropTypes.string,
    onClearAll: PropTypes.func,
    pageDataPushed: PropTypes.any,
    refinementLoading: PropTypes.bool
}

export default ProductListPageTemplate
