import React, {Fragment, useEffect, useState} from 'react'
import {TabletOrSmaller, Desktop} from '../media-queries'
import {Accordion, AccordionItem} from 'progressive-web-sdk/dist/components/accordion'
import Link from 'progressive-web-sdk/dist/components/link'

const RefineBy = ({
    refinements,
    setReFinedItems,
    selectedRefine,
    checkScroll,
    setrefineIds = (e) => e,
    renderCategoryAccordionItems = (e) => e,
    setLoading = () => {},
    shouldCategoryCollapse = false
}) => {
    const [refineIds, setRefineIds] = useState([])
    const [showMore, setShowMore] = useState(true)

    useEffect(() => {
        let ids = []
        let closedIds = []
        setRefineIds(ids)
        refinements &&
            refinements.map((item, index) => {
                if (
                    index !== 0 &&
                    item.hasOwnProperty('values') &&
                    item.attribute_id != 'cgid' &&
                    item.attribute_id != 'name'
                ) {
                    ids.push(item.attribute_id)
                } else {
                    closedIds.push(item.attribute_id)
                }
            })

        let firstTwoId = ids.slice(0, 2)
        setRefineIds([...firstTwoId])
        setrefineIds([...refineIds])
    }, [refinements])

    const handleShowHide = (event, clickedButton) => {
        if (typeof window !== 'undefined' && window.innerWidth > 1023) {
            event.target.previousSibling.style.setProperty(
                'max-height',
                `${event.target.previousSibling.scrollHeight}px`
            )
            if (clickedButton === 'more') {
                event.target.previousSibling.style.setProperty('overflow-y', 'scroll')
            } else if (clickedButton === 'less') {
                event.target.previousSibling.style.setProperty('overflow-y', 'hidden')
            }
        }

        event.target.previousSibling.classList.toggle('expanded')
    }

    const handleItemClick = (event) => {
        event.preventDefault()
        //for closing all opened refine groups
        setLoading(true)
        if (
            document.getElementsByClassName('accordian-in-mobile').length > 0 &&
            document
                .getElementById(`accordion__item-accord-category`)
                .classList.contains('pw-accordion--is-open')
        ) {
            document.querySelector(`#accordion__item-accord-category button`).click()
        }
        if (
            document.getElementsByClassName('accordian-in-mobile').length > 0 &&
            document.querySelector('.refinement-title.expanded') !== null
        ) {
            document.querySelector('.refinement-title.expanded').click()
            if (document.querySelector('.refinement-title.expanded') !== null) {
                document.querySelector('.refinement-title.expanded').classList.toggle('expanded')
            }
        }
        if (document.getElementsByClassName('accordian-in-mobile').length > 0) {
            setTimeout(() => {
                refinements.forEach((item) => {
                    document.getElementById(`accordion__item-${item.attribute_id}`) &&
                        document
                            .getElementById(`accordion__item-${item.attribute_id}`)
                            .classList.contains('pw-accordion--is-open') &&
                        document
                            .querySelector(`#accordion__item-${item.attribute_id} button`)
                            .click()
                })
            }, 100)
        }

        //setting selected refine array
        setReFinedItems(
            event.target.dataset.value,
            event.target.dataset.refine,
            event.target.dataset.id,
            event.target.dataset.label
        )
    }

    const renderRefineData = () => {
        let items = ''
        let renderData =
            refinements &&
            refinements.map((item, index) => {
                let tempItems = {...item}
                if (index !== 0 && item.hasOwnProperty('values')) {
                    return (
                        <AccordionItem
                            key={`${item.label} + ${index}`}
                            className={item.label}
                            header={item.label}
                            iconPosition="end"
                            id={item.attribute_id}
                            onClose={() => {
                                !showMore && setShowMore(true)
                            }}
                        >
                            <ul data-cmp="refineByElement" className="refine-categories-list">
                                {item.values &&
                                    item.values.map((data, count) => {
                                        return (
                                            <Fragment key={`refinement-item ${count}`}>
                                                <li
                                                    className={`refinement-item ${
                                                        selectedRefine.findIndex(
                                                            (selectedItem) =>
                                                                selectedItem.name == data.value &&
                                                                selectedItem.id ===
                                                                    item.attribute_id
                                                        ) > -1
                                                            ? 'selected'
                                                            : ''
                                                    }`}
                                                >
                                                    <Link
                                                        className={`refinement-link refinement-link-${item.attribute_id}`}
                                                        data-id={item.attribute_id}
                                                        data-value={data.value}
                                                        data-refine={item.label}
                                                        data-label={data.label}
                                                        onClick={(event) => handleItemClick(event)}
                                                    >
                                                        <span
                                                            className="refinement-checkbox"
                                                            data-id={item.attribute_id}
                                                            data-value={data.value}
                                                            data-refine={item.label}
                                                            data-label={data.label}
                                                        ></span>
                                                        {data.label}
                                                    </Link>
                                                </li>
                                            </Fragment>
                                        )
                                    })}
                            </ul>
                            {item.values.length > 6 && (
                                <>
                                    <span
                                        className={`read-more-trigger ${
                                            typeof window !== 'undefined' &&
                                            window.innerWidth > 1023
                                                ? showMore
                                                    ? 'show-more'
                                                    : 'show-less'
                                                : ''
                                        }`}
                                        onClick={(event) => {
                                            handleShowHide(event, showMore ? 'more' : 'less')
                                            setShowMore(!showMore)
                                            checkScroll()
                                        }}
                                    >
                                        {showMore ? 'Show More' : 'Show Less'}
                                    </span>
                                </>
                            )}
                        </AccordionItem>
                    )
                }
            })
        return renderData
    }

    return (
        <>
            <Desktop>
                <Accordion
                    onOpen={() => checkScroll('onOpen')}
                    onClose={checkScroll}
                    onOpened={() => checkScroll('onOpened')}
                    onClosed={checkScroll}
                    singleItemOpen={true}
                    {...(shouldCategoryCollapse && shouldCategoryCollapse.current !== 'false'
                        ? {
                              initialOpenItems: ['accord-category']
                          }
                        : {})}
                    duration={0}
                >
                    {renderCategoryAccordionItems()}
                    {refinements && refineIds.length > 0 && renderRefineData()}
                </Accordion>
            </Desktop>
            <TabletOrSmaller>
                {refinements && refineIds.length > 0 ? (
                    <div id="refine-by" className="refineby-contanier">
                        <div className="accordian-in-mobile">
                            <Accordion
                                singleItemOpen={false}
                                className="category-refinement"
                                duration={150}
                                easing="ease-out"
                            >
                                {renderRefineData()}
                            </Accordion>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
            </TabletOrSmaller>
        </>
    )
}

export default RefineBy
