/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'

import Icon from 'progressive-web-sdk/dist/components/icon'

const Select = (props) => {
    const {
        addButtonIcon,
        currentValue,
        defaultSelect,
        disableIcon,
        headerSearch,
        id,
        isError,
        onClickSelect,
        onClose,
        onOpen,
        optionIds,
        options,
        reset,
        setOption,
        tooltipText,
        showAllCategory,
        bool = false
    } = props
    const [selected, setSelected] = useState('All')
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [focusedIndex, setFocusedIndex] = useState(0)
    const [opened, setOpened] = useState(false)
    const [showToolTip, setShowToolTip] = useState(true)
    const selectRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.offsetX <= event.target.clientWidth) {
                selectRef.current && !selectRef.current.contains(event.target) && setOpened(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        opened && onOpen && onOpen()
        !opened && onClose && onClose()
        if (opened && options) {
            let optionElem =
                selectedIndex === 0
                    ? document.getElementById(`${id}_${selected}`)
                    : document.getElementById(`${selected}_${selectedIndex}`)
            optionElem && optionElem.focus()
        }
    }, [opened])

    useEffect(() => {
        reset && handleSelect(options[0], 0)
    }, [reset])

    useEffect(() => {
        setOption &&
            handleSelect(options[options.indexOf(setOption)], options.indexOf(setOption), 'select')
    }, [setOption])

    useEffect(() => {
        headerSearch &&
            setOption &&
            handleSelect(options[options.indexOf(setOption)], options.indexOf(setOption))
    }, [options])

    useEffect(() => {
        setSelected(setOption)
        setOpened(false)
    }, [showAllCategory])

    useEffect(() => {
        if (options.length > 0 && defaultSelect && options.indexOf(defaultSelect) >= 0)
            handleSelect(defaultSelect, options.indexOf(defaultSelect))
        else if ((!defaultSelect || defaultSelect === 'undefined') && !setOption)
            handleSelect(options[0], 0)
    }, [defaultSelect])

    const handleSelect = (option, index, data) => {
        data ? sessionStorage.setItem('setCategory', data) : ''
        if (option === undefined) {
            setSelected(options[0])
            setSelectedIndex(0)
            currentValue('Select')
        } else {
            setSelected(option)
            setSelectedIndex(index)
            setFocusedIndex(index)
            setOpened(false)
            currentValue(option)
        }
        try {
            if (headerSearch && options.length > 1) {
                sessionStorage.setItem(
                    'topLevelCategory',
                    data && options[index] !== undefined ? options[index] : 'All'
                )
            }
            if (headerSearch && optionIds && optionIds.length > 1) {
                sessionStorage.setItem('topLevelCategoryId', data ? optionIds[index] : '')
            }
        } catch (error) {
            console.error('Error storing in session: ', error)
        }
    }

    const getOptions = (focusedIndex) => {
        return options.map((o, i) => {
            return (
                <Option
                    key={i}
                    option={o}
                    onSelect={(opt) => handleSelect(opt, i, 'select')}
                    highlighted={o === selected && selectedIndex === i}
                    id={i === 0 ? `${id}_${o}` : `${o}_${i}`}
                    index={i}
                    focusedIndex={i === focusedIndex}
                />
            )
        })
    }

    const handleNav = (e) => {
        e.preventDefault()
        if (headerSearch) {
            setShowToolTip(false)
        }
        let newIndex
        if (e.key === 'ArrowDown')
            newIndex = focusedIndex < options.length - 1 ? focusedIndex + 1 : 0
        else if (e.key === 'ArrowUp')
            newIndex = focusedIndex > 0 ? focusedIndex - 1 : options.length - 1
        else if (e.key === 'Enter') {
            let elem = document.getElementById(`${options[focusedIndex]}_${focusedIndex}`)
            elem && elem.click()
            return
        } else if (/[a-zA-Z]/.test(e.key)) {
            for (let i = 0; i < options.length; i++) {
                if (e.key.toLowerCase() === options[i].charAt(0).toLowerCase()) {
                    newIndex = i
                    break
                }
            }
        }
        let optionElem =
            newIndex === 0
                ? document.getElementById(`${id}_${options[newIndex]}`)
                : document.getElementById(`${options[newIndex]}_${newIndex}`)
        if (optionElem) {
            optionElem.tabIndex = '-1'
            optionElem.focus()
            setFocusedIndex(newIndex)
        }
    }

    const onClick = () => {
        if (headerSearch) {
            setShowToolTip(opened)
        }
        setOpened(!opened)
    }

    const onClickList = () => {
        if (headerSearch) {
            onClickSelect()
        }
    }

    const onMouseEnter = () => {
        if (!opened && headerSearch) {
            setShowToolTip(true)
        }
    }

    return (
        <div
            className={`tooltip t-select selectric ${opened ? 't-select__open' : ''} ${
                isError ? 't-select__error-border' : ''
            }`}
            onMouseDown={(e) => e.preventDefault()}
            onClick={onClick}
            onFocus={() => setOpened(true)}
            onBlur={() => setOpened(false)}
            ref={selectRef}
            id={id ? id : 'select'}
            tabIndex="0"
            onKeyDown={(e) => handleNav(e)}
            onMouseEnter={onMouseEnter}
            aria-selected="false"
        >
            <div className={`t-select__select-area ${opened ? 'open' : ''}`}>
                <span className="t-select__span" id={id + 'selectPerPageCount'}>
                    {bool && <div className="label">({selected})</div>}
                    {!bool && <div className="label">{selected}</div>}
                </span>
                {!disableIcon && (
                    <Icon
                        name={opened ? 'chevron-up' : 'chevron-down'}
                        className="t-select__icon"
                    />
                )}
                {addButtonIcon && (
                    <b className="button">
                        <svg className="icon-arrow breadcrumb-next-icon">
                            <use xlinkHref="#icon-arrow"></use>
                        </svg>
                    </b>
                )}
            </div>

            <div className="t-select__listContainer selectric-scroll">
                <ul
                    className={`t-select__ul ${opened ? 't-select__show' : 't-select__hide'}`}
                    onClick={onClickList}
                    aria-hidden="true"
                >
                    {getOptions(focusedIndex)}
                </ul>
            </div>
            {showToolTip && tooltipText && <span className="tooltiptext">{tooltipText}</span>}
        </div>
    )
}

const Option = (props) => {
    const {option, onSelect, highlighted, id, index, focusedIndex} = props
    const handleClick = (e) => {
        e.preventDefault()
        onSelect(option)
    }

    return (
        <li
            onClick={(e) => handleClick(e)}
            className={`t-select__li ${highlighted && 't-select__highlighted'} ${focusedIndex &&
                't-select__active'}`}
            id={id}
            tabIndex={index}
            aria-hidden="true"
        >
            {option}
        </li>
    )
}

Option.propTypes = {
    option: PropTypes.any,
    onSelect: PropTypes.func,
    highlighted: PropTypes.bool,
    id: PropTypes.string,
    index: PropTypes.number,
    focusedIndex: PropTypes.any
}

Select.getTemplateName = () => {
    return 'select'
}

Select.propTypes = {
    addButtonIcon: PropTypes.bool,
    currentValue: PropTypes.func,
    defaultSelect: PropTypes.string,
    disableIcon: PropTypes.bool,
    headerSearch: PropTypes.bool,
    id: PropTypes.string,
    isError: PropTypes.bool,
    onClickSelect: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    optionIds: PropTypes.array,
    options: PropTypes.array,
    reset: PropTypes.bool,
    setOption: PropTypes.any,
    sortSelect: PropTypes.any,
    tooltipText: PropTypes.string
}

export default Select
