import React from 'react'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import SkeletonText from 'progressive-web-sdk/dist/components/skeleton-text'

const ProductListSkeletonWithoutSidebar = ({list = []}) => {
    return (
        <div className="container-skeleton">
            {list.map((_, index) => (
                <>
                    <div className="product-tile-skeleton">
                        <SkeletonBlock
                            width="150px"
                            height={'150px'}
                            style={{marginLeft: 'auto', marginRight: 'auto'}}
                        />
                        <SkeletonText width="50%" />
                        <SkeletonText width="40%" />
                        <SkeletonText width="10%" />
                        <SkeletonText width="80%" />
                        <SkeletonText width="40%" />
                        <SkeletonBlock width="100%" height="20px" />
                        <SkeletonText width="30%" />
                        <SkeletonBlock width={'100%'} height={'30px'} />
                    </div>
                    {/* Mobile Devices PLP and SLP Pages */}
                    {index !== list?.length - 1 &&
                        (index + 1) % 2 == 0 &&
                        typeof window !== 'undefined' &&
                        window.innerWidth <= 767 && (
                            <hr
                                style={{
                                    width: '100%',
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}
                            />
                        )}

                    {/* Mobile Devices PLP and SLP Pages Last Line */}
                    {index === list?.length - 1 &&
                        typeof window !== 'undefined' &&
                        window.innerWidth <= 767 && (
                            <hr
                                style={{
                                    width: '100%',
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}
                            />
                        )}

                    {/* Tablet and Higher Devices PLP and SLP Pages */}
                    {index !== list?.length - 1 &&
                        (index + 1) % 3 == 0 &&
                        typeof window !== 'undefined' &&
                        window.innerWidth >= 768 && (
                            <hr
                                style={{
                                    width: '100%',
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}
                            />
                        )}

                    {/* Tablet and Higher Devices PLP and SLP Pages Last Line */}
                    {index === list?.length - 1 &&
                        typeof window !== 'undefined' &&
                        window.innerWidth >= 768 && (
                            <hr
                                style={{
                                    width: '100%',
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                }}
                            />
                        )}
                </>
            ))}
        </div>
    )
}

export default ProductListSkeletonWithoutSidebar
