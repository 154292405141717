/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {Fragment, useEffect, useState, useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import {Desktop, TabletOrSmaller} from '../../components/media-queries'
import SkeletonText from 'progressive-web-sdk/dist/components/skeleton-text'
import SkeletonBlock from 'progressive-web-sdk/dist/components/skeleton-block'
import Icon from 'progressive-web-sdk/dist/components/icon'
import {Accordion, AccordionItem} from 'progressive-web-sdk/dist/components/accordion'
import RefineBy from '../refine-by'
import Link from 'progressive-web-sdk/dist/components/link'
import {Link as WHSLink} from 'react-router-dom'
import CategoriesBar from '../../pages/search-list/categoriesBar'
import {v4 as uuidv4} from 'uuid'
import {GlobalStateContext} from '../../components/global-state'
import {useLocation} from 'react-router-dom'

const SidebarTemplate = ({
    closeButton,
    sidebarData,
    refinements,
    refinedItems,
    setReFinedItems,
    clearAllRefine,
    handleRefineRemove,
    applyFilter,
    handleClose,
    setInitiallyClosed,
    showCharts,
    isSearchListing,
    queryString,
    setLoading,
    refinementLoading,
    classNames,
    goToHref
}) => {
    const [showMore, setShowMoreLabel] = useState(true)
    const [showMoreForAccordion, setShowMoreLabelForAccordion] = useState(true)

    // Moved contents from refine by to sidebar Template
    const location = useLocation()
    const [top, setTop] = useState(0)
    const [refineIds, setrefineIds] = useState([])

    const queryStringValue = useRef(false)
    const cgidStringValue = useRef(false)
    const categoryStringValue = useRef(false)

    const globalState = useContext(GlobalStateContext)
    let query = ''

    const [left, setLeft] = useState(0)
    const offsetTop = useRef()
    const offsetLeft = useRef()
    const shouldCategoryCollapse = useRef(true)

    const previousScrollYPosition = useRef()

    if (location.search) {
        query = new URLSearchParams(location.search)
        query.delete('p')
        if (query.has('q') && !queryStringValue.current) {
            queryStringValue.current = query.get('q')
        }
        if (query.has('q') && !cgidStringValue.current) {
            cgidStringValue.current = query.get('cgid')
        }
        if (query.has('q') && !categoryStringValue.current) {
            categoryStringValue.current = query.get('category')
        }
    }

    useEffect(() => {
        let query = new URLSearchParams(location.search)
        if (
            query.has('q') &&
            (queryStringValue.current !== query.get('q') ||
                cgidStringValue.current !== query.get('cgid') ||
                categoryStringValue.current !== query.get('category')) &&
            !refinementLoading
        ) {
            let categoryAccordion = document.getElementById('accordion__header-accord-category')
            let secondaryEle = document?.getElementById('sub-secondary')
            shouldCategoryCollapse.current = true

            if (secondaryEle.style.position === 'relative') {
                if (categoryAccordion) {
                    categoryAccordion.getAttribute('aria-selected') === 'false' &&
                        categoryAccordion.click()
                }
            } else {
                checkScroll()
            }
        }
        return () => {
            queryStringValue.current = query.get('q')
            cgidStringValue.current = query.get('cgid')
            categoryStringValue.current = query.get('category')
        }
    }, [location.search])

    useEffect(() => {
        if (
            (document?.getElementById('sub-secondary')?.offsetTop || top) &&
            window.innerWidth >= 1024
        ) {
            document?.getElementById('sub-secondary')?.style?.setProperty('position', 'relative')
            document?.getElementById('sub-secondary')?.style?.setProperty('left', 0)
            document?.getElementById('sub-secondary')?.style?.setProperty('top', 0)
            offsetTop.current = document?.getElementById('sub-secondary')?.offsetTop || top
            setTop(document?.getElementById('sub-secondary')?.offsetTop || top)
            offsetLeft.current = document?.getElementById('sub-secondary')?.offsetLeft || left
            setLeft(document?.getElementById('sub-secondary')?.offsetLeft || left)
            if (offsetTop.current) {
                previousScrollYPosition.current = window.scrollY
                window.addEventListener('scroll', checkScroll)
            }
        }
        return () => {
            if (offsetTop.current) {
                window.removeEventListener('scroll', checkScroll)
            }
        }
    }, [refineIds])

    useEffect(() => {
        if (window.innerWidth >= 1024) {
            previousScrollYPosition.current = window.scrollY
            checkScroll()
        }
    }, [
        globalState?.categoryProductData?.[
            `${location.pathname}${query.toString() && '?' + query.toString()}`
        ]?.length,
        typeof document !== 'undefined' && document?.getElementById('primary')?.offsetHeight
    ])

    const checkScroll = (value) => {
        let secondaryEle = document?.getElementById('sub-secondary')
        let primaryEle = document?.getElementById('primary')
        let leftSideBar = document?.getElementById('leftSideBar')
        let boundary = primaryEle?.offsetHeight + primaryEle?.offsetTop - 5
        let valueOfTop = 0

        if (
            (previousScrollYPosition.current > window.scrollY &&
                window.scrollY > offsetTop.current &&
                window.scrollY + secondaryEle?.scrollHeight + 100 < boundary) ||
            (window.scrollY > offsetTop.current &&
                window.scrollY + secondaryEle?.scrollHeight + 100 < boundary &&
                document?.getElementById('site-header')?.classList.contains('header-fixed'))
        ) {
            valueOfTop = document?.getElementById('site-header')?.offsetHeight
        }
        // Fixed Position
        if (window.innerWidth >= 1024) {
            if (value && value === 'onOpen') {
                secondaryEle?.style?.setProperty('max-height', `${secondaryEle.scrollHeight}px`)
                secondaryEle?.style?.setProperty('overflow', 'hidden')
            }

            if (value && value === 'onOpened') {
                secondaryEle?.style?.setProperty('max-height', 'unset')
                secondaryEle?.style?.setProperty('overflow', 'hidden')
            }

            if (window.scrollY <= offsetTop.current) {
                secondaryEle?.style?.setProperty('position', 'relative')
                secondaryEle?.style?.setProperty('left', 0)
                secondaryEle?.style?.setProperty('top', 0)
                secondaryEle?.style?.setProperty('width', 'auto')
                if (shouldCategoryCollapse.current !== 'false') {
                    shouldCategoryCollapse.current = true
                }
            } else if (
                window.scrollY >= offsetTop.current &&
                boundary >= window.scrollY + secondaryEle.scrollHeight
            ) {
                secondaryEle?.style?.setProperty('left', `${offsetLeft.current || left}px`)
                secondaryEle?.style?.setProperty('width', `${leftSideBar.offsetWidth}px`)
                secondaryEle?.style?.setProperty('position', 'fixed')
                secondaryEle?.style?.setProperty('top', `${valueOfTop}px`)
            } else if (boundary <= window.scrollY + secondaryEle?.scrollHeight) {
                secondaryEle?.style?.setProperty('width', `${leftSideBar.offsetWidth}px`)
                secondaryEle?.style?.setProperty('position', 'absolute')
                secondaryEle?.style?.setProperty('left', `${offsetLeft.current || left}px`)
                secondaryEle?.style?.setProperty('top', `${boundary - secondaryEle.scrollHeight}px`)
            }
            if (window.scrollY >= offsetTop.current) {
                if (shouldCategoryCollapse.current && shouldCategoryCollapse.current !== 'false') {
                    let categoryAccordion = document.getElementById(
                        'accordion__header-accord-category'
                    )
                    shouldCategoryCollapse.current = 'false'
                    if (categoryAccordion) {
                        categoryAccordion.getAttribute('aria-selected') === 'true' &&
                            categoryAccordion.click()
                    }
                }
            }
        }

        previousScrollYPosition.current = window.scrollY
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', positionElementsDynamically)
        }
        return () => {
            window.removeEventListener('resize', positionElementsDynamically)
        }
    }, [])

    const positionElementsDynamically = () => {
        offsetLeft.current = document.getElementsByClassName('refinements-wrapper')[0]?.offsetLeft
    }

    // Moved contents from refine by to sidebar Template

    const handleShowHide = () => {
        if (showMore) {
            $('li#refinement-item-li, li#refinements-item-li').removeClass('to-hide')
        } else {
            let itemLength = $('li#refinement-item-li').length
            let itemLength1 = $('li#refinements-item-li').length
            $('ul li#refinement-item-li')
                .slice(-(itemLength - 10))
                .addClass('to-hide')
            $('ul li#refinements-item-li')
                .slice(-(itemLength1 - 10))
                .addClass('to-hide')
        }
        setShowMoreLabel(!showMore)
    }

    const handleCategoriesShowHide = (event) => {
        if (typeof window !== 'undefined' && window.innerWidth > 1023) {
            event.target.previousSibling.style.setProperty(
                'max-height',
                `${event.target.previousSibling.scrollHeight}px`
            )
        }
        if (showMoreForAccordion) {
            event.target.previousSibling.style.setProperty('overflow-y', 'scroll')
            document.querySelectorAll('#category-level-2')[0].childNodes.forEach((ele) => {
                if (ele.tagName == 'LI') ele.className = 'refinement-item'
            })
        } else {
            event.target.previousSibling.style.setProperty('overflow-y', 'hidden')
            document.querySelectorAll('#category-level-2')[0].childNodes.forEach((ele, index) => {
                if (index > 5 && ele.tagName == 'LI') ele.className = 'refinement-item to-hide'
            })
        }
        setShowMoreLabelForAccordion(!showMoreForAccordion)
    }

    const setRefine = (item, type, id, label) => {
        let tempObj = {name: item, type: type, id: id, include: true, label: label}
        if (
            id == 'price' &&
            refinedItems.findIndex((data) => data.id == id && data.name !== item) > -1
        ) {
            let exist = refinedItems.findIndex((data) => data.id == id)
            if (exist > -1) {
                let temp = [...refinedItems]
                temp.splice(exist, 1, tempObj)
                setReFinedItems([...temp])
            }
        } else if (refinedItems.findIndex((data) => data.name == item) > -1) {
            let exist = refinedItems.findIndex((data) => data.name == item)
            let temp = [...refinedItems]
            temp.splice(exist, 1)
            setReFinedItems([...temp])
        } else {
            setReFinedItems([...refinedItems, tempObj])
        }
    }

    useEffect(() => {
        let temp = [...refinedItems]
        temp.map((refinedItem) => {
            refinements.forEach((dataSet) => {
                if (
                    dataSet &&
                    dataSet.values &&
                    dataSet.values.length > 0 &&
                    refinedItem.id === dataSet.attribute_id
                ) {
                    let flag = false
                    dataSet.values.forEach((data) => {
                        if (data && refinedItem.name === data.value) {
                            flag = true
                            refinedItem.include = true
                        }
                    })
                    if (flag === false) {
                        refinedItem.include = false
                    }
                }
            })
        })
        setTimeout(() => {
            $('.overlay') && $('.overlay').removeClass('active')
        }, 800)
        // setReFinedItems([...temp])
    }, [refinements])

    const setRefineBadges = () => {
        let data = []
        let priceIndex = refinements.findIndex((item) => item.attribute_id == 'price')
        refinements.forEach((item, index) => {
            data[index] = refinedItems
                .filter((data) => data.id == item.attribute_id && data.include == true)
                .map((result, i) => {
                    return (
                        <span
                            className="filter-selection"
                            data-divider=""
                            data-selected-filter-id="Product Type"
                            key={`span_${result}_${i}`}
                        >
                            <span className="filter-selection-name">{result.type}: </span>
                            <span className="filter-selection-value">
                                {result.label}
                                <Link
                                    className="filter-selection-delete"
                                    href=""
                                    data-value={result.name}
                                    onClick={(event) => {
                                        handleRefineRemove(event)
                                        setLoading(true)
                                    }}
                                    title="Remove"
                                >
                                    <svg className="icon-close">
                                        <Icon name="close" title="Close" size="small" />
                                    </svg>
                                </Link>
                            </span>
                        </span>
                    )
                })
        })
        if (priceIndex > -1) {
            let temp = data[priceIndex]
            data[priceIndex] = data[0]
            data[0] = temp
        }
        return data
    }

    const renderCategoryAccordionItems = () => {
        return (sidebarData &&
            sidebarData.parent_category_tree === undefined &&
            !sidebarData.error) ||
            (sidebarData.parent_category_tree !== undefined &&
                sidebarData.parent_category_tree.length > 0) ||
            sidebarData.name ? (
            <AccordionItem
                className=""
                header="Categories"
                id="accord-category"
                iconPosition="end"
                onClosed={() => {
                    setShowMoreLabelForAccordion(true)
                }}
            >
                {sidebarData.parent_category_tree === undefined ? (
                    <CategoriesBar searchPhrase={queryString} categories={sidebarData} />
                ) : (
                    <div
                        className="refinement expanded category-refinement"
                        data-refinement-id="Category"
                    >
                        <div className="accordion-content">
                            {sidebarData.parent_category_tree !== undefined &&
                                sidebarData.parent_category_tree.length > 0 &&
                                sidebarData.parent_category_tree.map((child, count) => {
                                    return count < sidebarData.parent_category_tree.length - 1 ? (
                                        <div className="refinement-top-level" key={count}>
                                            <WHSLink
                                                className="refinement-top-link"
                                                rel="follow"
                                                to={goToHref(
                                                    isSearchListing
                                                        ? {
                                                              pathname: `/search/`,
                                                              search: new URLSearchParams(
                                                                  `q=${queryString}&cgid=${child.id.toUpperCase()}&category=${
                                                                      child.name
                                                                  }`
                                                              ).toString(),
                                                              state: {
                                                                  skeleton: child.c_template
                                                              }
                                                          }
                                                        : {
                                                              pathname: `/${child.c_pageUrl?.toLowerCase()}/`,
                                                              search: '',
                                                              state: {
                                                                  skeleton: child.c_template
                                                              }
                                                          }
                                                )}
                                                onClick={() => {
                                                    setLoading(true)
                                                }}
                                                title={'Go to Category: ' + child.name}
                                            >
                                                <h3>{child.name}</h3>
                                            </WHSLink>
                                        </div>
                                    ) : null
                                })}

                            {sidebarData.name && (
                                <ul
                                    id="category-level-1"
                                    className="category-level-1 js-collapsible category-level-list expanded"
                                >
                                    <li className="refinement-item expandable">
                                        <span className="refinement-leaf-category">
                                            {sidebarData.name}
                                            <svg className="icon-arrow_v2 refinement-cat-icon">
                                                <title>Go to Category: {sidebarData.name}</title>
                                                <use xlinkHref="#icon-arrow_v2"></use>
                                                <svg id="icon-arrow_v2" viewBox="0 0 10 10">
                                                    <path
                                                        id={uuidv4() + 'ic_arrow'}
                                                        className="cls-1"
                                                        d="M337.643 713.651h7.176l-3.135 3.215a.677.677 0 0 0 0 .937.629.629 0 0 0 .9 0l4.233-4.343a.669.669 0 0 0 0-.928l-4.227-4.352a.628.628 0 0 0-.905 0 .669.669 0 0 0 0 .929l3.128 3.223h-7.175a.652.652 0 0 0-.642.661.652.652 0 0 0 .647.658z"
                                                        transform="translate(-337 -708)"
                                                    ></path>
                                                </svg>
                                            </svg>
                                        </span>
                                        <ul
                                            id="category-level-2"
                                            className="category-level-2 js-collapsible category-level-list expanded"
                                        >
                                            {sidebarData.categories &&
                                                (sidebarData.parent_category_tree !== undefined &&
                                                    sidebarData.parent_category_tree.length > 0) &&
                                                sidebarData.categories.map((child, count) => {
                                                    return (
                                                        <li
                                                            id="refinement-item-li"
                                                            className={`refinement-item ${count >=
                                                                6 && 'to-hide'}`}
                                                            key={child.name}
                                                        >
                                                            <WHSLink
                                                                id="refinement-items-all"
                                                                className="refinement-link-category refinement-link-category-child"
                                                                title={
                                                                    'Go to Category: ' + child.name
                                                                }
                                                                rel="follow"
                                                                onClick={() => {
                                                                    setLoading(true)
                                                                }}
                                                                to={goToHref(
                                                                    isSearchListing
                                                                        ? {
                                                                              pathname: `/search/`,
                                                                              search: new URLSearchParams(
                                                                                  `q=${queryString}&cgid=${child.id.toUpperCase()}&category=${
                                                                                      child.name
                                                                                  }`
                                                                              ).toString(),
                                                                              state: {
                                                                                  skeleton:
                                                                                      child.c_template
                                                                              }
                                                                          }
                                                                        : {
                                                                              pathname: `/${child.c_pageUrl?.toLowerCase()}/`,
                                                                              search: '',
                                                                              state: {
                                                                                  skeleton:
                                                                                      child.c_template
                                                                              }
                                                                          }
                                                                )}
                                                            >
                                                                <div className="sidebar-text">
                                                                    {child.name}
                                                                </div>
                                                            </WHSLink>
                                                        </li>
                                                    )
                                                })}
                                        </ul>
                                        {sidebarData.categories &&
                                            sidebarData.categories.length > 6 && (
                                                <span
                                                    className="read-more-trigger"
                                                    onClick={(event) =>
                                                        handleCategoriesShowHide(event)
                                                    }
                                                >
                                                    {showMoreForAccordion
                                                        ? 'Show More'
                                                        : 'Show Less'}
                                                </span>
                                            )}
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                )}
            </AccordionItem>
        ) : null
    }

    return (
        <>
            <div id="sub-secondary" className={classNames}>
                <TabletOrSmaller>
                    <div className="refinement-close js-refinement-close">
                        <svg className="icon-close" onClick={(event) => handleClose(event)}>
                            <Icon name="close" title="Close" size="small" />
                        </svg>
                    </div>
                    <div className="refinement-refineby">
                        <span className="refinement-refineby-text">Refine By:</span>
                    </div>
                </TabletOrSmaller>

                <div className="refinements-wrapper">
                    <TabletOrSmaller>
                        {refinements &&
                            refinements.length > 0 &&
                            refinedItems.filter((item) => item.include == true && item.id !== 's')
                                .length > 0 && (
                                <div className="filter-applied">
                                    <span className="filter-refined-by">Refined by</span>
                                    {setRefineBadges()}

                                    <Link
                                        className="filter-selection-clear"
                                        href=""
                                        onClick={(event) => clearAllRefine(event)}
                                    >
                                        Clear all{' '}
                                    </Link>
                                </div>
                            )}
                    </TabletOrSmaller>

                    <Desktop>
                        <div id="secondary">
                            <div id="categories_accordion">
                                {showCharts ? (
                                    <Accordion
                                        onOpen={() => checkScroll('onOpen')}
                                        onClose={checkScroll}
                                        onOpened={() => checkScroll('onOpened')}
                                        onClosed={checkScroll}
                                        singleItemOpen={true}
                                        initialOpenItems={['accord-category']}
                                        duration={0}
                                    >
                                        {renderCategoryAccordionItems()}
                                    </Accordion>
                                ) : !refinementLoading ? (
                                    <RefineBy
                                        refinements={refinements}
                                        setReFinedItems={setRefine}
                                        selectedRefine={refinedItems}
                                        setInitiallyClosed={setInitiallyClosed}
                                        setLoading={setLoading}
                                        checkScroll={checkScroll}
                                        setrefineIds={setrefineIds}
                                        renderCategoryAccordionItems={renderCategoryAccordionItems}
                                        shouldCategoryCollapse={shouldCategoryCollapse}
                                    />
                                ) : (
                                    <>
                                        <SkeletonBlock height={'300px'} />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                        <SkeletonBlock height={'300px'} />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                        <SkeletonText type="h1" />
                                    </>
                                )}
                            </div>
                        </div>
                        {refinements &&
                            refinements.length > 0 &&
                            refinedItems.filter((item) => item.include == true && item.id !== 's')
                                .length > 0 && (
                                <div className="filter-applied">
                                    <span className="filter-refined-by">Refined by</span>
                                    {setRefineBadges()}

                                    <Link
                                        className="filter-selection-clear"
                                        href=""
                                        onClick={(event) => clearAllRefine(event)}
                                    >
                                        Clear all{' '}
                                    </Link>
                                </div>
                            )}
                    </Desktop>

                    <TabletOrSmaller>
                        <Accordion
                            singleItemOpen={true}
                            className="category-refinement"
                            duration={150}
                            easing="ease-out"
                        >
                            <AccordionItem
                                className=""
                                header="Category"
                                id="accord-category"
                                iconPosition="end"
                            >
                                {sidebarData.parent_category_tree === undefined ? (
                                    <CategoriesBar
                                        searchPhrase={queryString}
                                        categories={sidebarData}
                                    />
                                ) : (
                                    <div className="">
                                        {sidebarData.parent_category_tree !== undefined &&
                                            sidebarData.parent_category_tree.length > 0 &&
                                            sidebarData.parent_category_tree.map((child, count) => {
                                                return count <
                                                    sidebarData.parent_category_tree.length - 1 ? (
                                                    <div
                                                        className="refinement-top-level 1111"
                                                        key={`${child.name}-${count}`}
                                                    >
                                                        <WHSLink
                                                            className="refinement-top-link"
                                                            rel="follow"
                                                            to={goToHref(
                                                                isSearchListing
                                                                    ? {
                                                                          pathname: `/search/`,
                                                                          search: (new URLSearchParams(`q=${queryString}&cgid=${child.id.toUpperCase()}&category=${
                                                                              child.name
                                                                          }`)).toString(),
                                                                          state: {
                                                                              skeleton:
                                                                                  child.c_template
                                                                          }
                                                                      }
                                                                    : {
                                                                          pathname: `/${child.c_pageUrl?.toLowerCase()}/`,
                                                                          search: '',
                                                                          state: {
                                                                              skeleton:
                                                                                  child.c_template
                                                                          }
                                                                      }
                                                            )}
                                                            title={'Go to Category: ' + child.name}
                                                            onClick={() => closeButton(false)}
                                                        >
                                                            <h3> {child.name}</h3>
                                                        </WHSLink>
                                                    </div>
                                                ) : null
                                            })}

                                        {sidebarData.name && (
                                            <ul
                                                id="category-level-1"
                                                className="category-level-1 js-collapsible category-level-list expanded"
                                            >
                                                <li className="refinement-item expandable">
                                                    <span className="refinement-leaf-category">
                                                        {sidebarData.name}
                                                        <svg className="icon-arrow_v2 refinement-cat-icon">
                                                            <title>
                                                                Go to Category: {sidebarData.name}
                                                            </title>
                                                            <use xlinkHref="#icon-arrow_v2"></use>
                                                            <svg
                                                                id="icon-arrow_v2"
                                                                viewBox="0 0 10 10"
                                                            >
                                                                <path
                                                                    id={uuidv4() + 'ic_arrow'}
                                                                    className="cls-1"
                                                                    d="M337.643 713.651h7.176l-3.135 3.215a.677.677 0 0 0 0 .937.629.629 0 0 0 .9 0l4.233-4.343a.669.669 0 0 0 0-.928l-4.227-4.352a.628.628 0 0 0-.905 0 .669.669 0 0 0 0 .929l3.128 3.223h-7.175a.652.652 0 0 0-.642.661.652.652 0 0 0 .647.658z"
                                                                    transform="translate(-337 -708)"
                                                                ></path>
                                                            </svg>
                                                        </svg>
                                                    </span>
                                                    <ul
                                                        id="category-level-2"
                                                        className="category-level-2 js-collapsible category-level-list"
                                                    >
                                                        {sidebarData.categories &&
                                                            (sidebarData.parent_category_tree !==
                                                                undefined &&
                                                                sidebarData.parent_category_tree
                                                                    .length > 0) &&
                                                            sidebarData.categories.map(
                                                                (child, count) => {
                                                                    return (
                                                                        <li
                                                                            id="refinements-item-li"
                                                                            className={`refinement-item ${count >=
                                                                                10 && 'to-hide'}`}
                                                                            key={child.name}
                                                                        >
                                                                            <WHSLink
                                                                                id="refinements-items-all"
                                                                                className="refinement-link-category refinement-link-category-child"
                                                                                title={
                                                                                    child.page_title
                                                                                }
                                                                                rel="follow"
                                                                                to={goToHref(
                                                                                    isSearchListing
                                                                                        ? {
                                                                                              pathname: `/search/`,
                                                                                              search: (new URLSearchParams(`q=${queryString}&cgid=${child.id.toUpperCase()}&category=${
                                                                                                  child.name
                                                                                              }`)).toString(),
                                                                                              state: {
                                                                                                  skeleton:
                                                                                                      child.c_template
                                                                                              }
                                                                                          }
                                                                                        : {
                                                                                              pathname: `/${child.c_pageUrl?.toLowerCase()}/`,
                                                                                              search:
                                                                                                  '',
                                                                                              state: {
                                                                                                  skeleton:
                                                                                                      child.c_template
                                                                                              }
                                                                                          }
                                                                                )}
                                                                                onClick={() =>
                                                                                    closeButton(
                                                                                        false
                                                                                    )
                                                                                }
                                                                            >
                                                                                <h3>
                                                                                    {child.name}
                                                                                </h3>
                                                                            </WHSLink>
                                                                        </li>
                                                                    )
                                                                }
                                                            )}
                                                        {sidebarData.categories &&
                                                            sidebarData.categories.length > 10 && (
                                                                <span
                                                                    className="read-more-trigger"
                                                                    onClick={(event) =>
                                                                        handleShowHide(event)
                                                                    }
                                                                    id=""
                                                                >
                                                                    {showMore
                                                                        ? 'Show More'
                                                                        : 'Show Less'}
                                                                </span>
                                                            )}
                                                    </ul>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                )}
                            </AccordionItem>
                        </Accordion>
                        {!showCharts && (
                            <RefineBy
                                refinements={refinements}
                                setLoading={setLoading}
                                setReFinedItems={(item, type, id, label) =>
                                    setRefine(item, type, id, label)
                                }
                                selectedRefine={refinedItems}
                                setInitiallyClosed={setInitiallyClosed}
                            />
                        )}
                    </TabletOrSmaller>
                </div>

                <TabletOrSmaller>
                    <div className="refinement-actions unselectable">
                        <Link
                            className="btn-white refinement-cancel js-refinement-cancel"
                            type="submit"
                            onClick={(event) => handleClose(event)}
                            href="#"
                        >
                            Cancel
                        </Link>
                        <Link
                            className="btn-blue refinement-apply js-refinement-apply"
                            onClick={(event) => applyFilter(event)}
                            type="submit"
                            href="#"
                        >
                            Apply
                        </Link>
                    </div>
                </TabletOrSmaller>
            </div>
        </>
    )
}

SidebarTemplate.propTypes = {
    sidebarData: PropTypes.object,
    refinements: PropTypes.array,
    closeButton: PropTypes.func,
    refinedItems: PropTypes.any,
    setReFinedItems: PropTypes.any,
    clearAllRefine: PropTypes.any,
    handleRefineRemove: PropTypes.any,
    applyFilter: PropTypes.any,
    handleClose: PropTypes.any,
    setInitiallyClosed: PropTypes.any,
    showCharts: PropTypes.any,
    isSearchListing: PropTypes.bool,
    queryString: PropTypes.string,
    classNames: PropTypes.string
}

export default SidebarTemplate
