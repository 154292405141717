import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {getAnalyticsManager} from '../../analytics'
import {PAGEVIEW} from 'progressive-web-sdk/dist/analytics-integrations/types'
import {GlobalStateContext, GlobalDispatchContext, STORE_PROPS} from '../../components/global-state'
import {TabletOrSmaller} from '../../components/media-queries'
import {isBrowser, breadcrumbArray, isEqual, pageView} from '../../utils/utils'
import {getFromLocal, getFromSession, getCookie} from '../../utils/storage-utils'
import ViewCategories from './viewCategories'
import PageTemplateWithData from '../../components/page-template-with-data'
import {useHistory} from 'react-router-dom'
import {useLocation} from 'react-router'
import PageTemplateSkeleton from '../home/skeleton'
import {Helmet} from 'react-helmet'

const CategoryLandingPage = (props) => {
    const {
        pageStructure,
        categories,
        categoryAsset,
        allSlotsData,
        meta,
        category,
        redirect = false,
        redirectDetails,
        externalRedirect = false,
        skeletonCategory,
        canonicalUrl
    } = props
    const location = useLocation()
    let {catUrl} = props

    let paramsCaturl = location.pathname.toLowerCase()
    const [isExpanded, setIsExpanded] = useState(false)
    const [pageDataPushed, setPageDataPushed] = useState(false)
    const analyticsManager = getAnalyticsManager()
    const history = useHistory()

    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)

    const cachedPageStructure = globalState[paramsCaturl]?.pageStructure || pageStructure

    const cachedCategories = globalState[paramsCaturl]?.categories || categories

    const cachedCategoryAsset = globalState[paramsCaturl]?.categoryAsset || categoryAsset

    const cachedAllSlotsData = globalState[paramsCaturl]?.allSlotsData || allSlotsData

    const cachedMeta = globalState[paramsCaturl]?.meta || meta

    useEffect(() => {
        if (
            pageStructure &&
            !(
                globalState[paramsCaturl]?.pageStructure &&
                isEqual(globalState[paramsCaturl]?.pageStructure, pageStructure) &&
                globalState[paramsCaturl]?.categories &&
                isEqual(globalState[paramsCaturl]?.categories, categories) &&
                globalState[paramsCaturl]?.categoryAsset &&
                isEqual(globalState[paramsCaturl]?.categoryAsset, categoryAsset) &&
                globalState[paramsCaturl]?.allSlotsData &&
                isEqual(globalState[paramsCaturl]?.allSlotsData, allSlotsData) &&
                globalState[paramsCaturl]?.meta &&
                isEqual(globalState[paramsCaturl]?.meta, meta)
            ) &&
            paramsCaturl.toLowerCase().replace(/\/$/, '') ===
                catUrl?.toLowerCase().replace(/\/$/, '') &&
            /\/$/.test(location.pathname)
        ) {
            dispatch({
                type: STORE_PROPS,
                payload: {
                    [paramsCaturl]: {
                        ...(globalState[paramsCaturl] || {}),
                        pageStructure: pageStructure,
                        categories: categories,
                        categoryAsset: categoryAsset,
                        allSlotsData: allSlotsData,
                        meta: meta
                    }
                }
            })
        }
    }, [
        paramsCaturl,
        catUrl,
        isEqual(globalState[paramsCaturl]?.pageStructure || {}, pageStructure || {}),
        isEqual(globalState[paramsCaturl]?.categories || {}, categories || {}),
        isEqual(globalState[paramsCaturl]?.categoryAsset || {}, categoryAsset || {}),
        isEqual(globalState[paramsCaturl]?.allSlotsData || {}, allSlotsData || {}),
        isEqual(globalState[paramsCaturl]?.meta || {}, meta || {})
    ])

    useEffect(() => {
        if (redirect && redirectDetails) {
            if (externalRedirect) {
                window.open(redirectDetails, '_blank')
            } else history.replace(redirectDetails)
        }
    }, [redirect, category?.id])

    useEffect(() => {
        if (typeof categories !== 'undefined') {
            let analyticbasketInfo = ''
            if (typeof window !== 'undefined') {
                analyticbasketInfo = getFromLocal('analyticBasket')
                if (analyticbasketInfo != undefined && analyticbasketInfo.length) {
                    analyticbasketInfo = JSON.parse(analyticbasketInfo)
                } else {
                    analyticbasketInfo = {
                        cartValue: 0,
                        cartItemsNum: 0,
                        cartUniqueItemsNum: 0
                    }
                }
            } else {
                analyticbasketInfo = {
                    cartValue: 0,
                    cartItemsNum: 0,
                    cartUniqueItemsNum: 0
                }
            }
            const analyticBreadcrumb = breadcrumbArray()
            const analyticData = {
                event: 'pageDataPushed',
                templateName: 'category-page',
                pageType: 'category',
                pageURL: window.location.href,
                pageTitle: meta?.title,
                originalLocation:
                    getFromSession('originalLocation') !== ''
                        ? getFromSession('originalLocation')
                        : window.location.href,
                categoryID: categories.id,
                categoryName: categories.name || categories.c_h1Tag,
                visitorStatus:
                    getCookie('isUserLoggedIn') && getCookie('isUserLoggedIn') === 'true'
                        ? 'regular-logged'
                        : 'guest',
                userId: getCookie('customerId') ? getCookie('customerId') : null,
                cartValue: analyticbasketInfo.cartValue,
                cartItemsNum: analyticbasketInfo.cartItemsNum,
                cartUniqueItemsNum: analyticbasketInfo.cartUniqueItemsNum
            }
            if (Object.keys(analyticBreadcrumb).length > 0) {
                analyticData['breadcrumb'] = analyticBreadcrumb
            }
            if (!pageDataPushed) {
                pageView(meta?.title, 'category-page', 'category')
                analyticsManager.track(PAGEVIEW, analyticData)
                setPageDataPushed(true)
            }
        }
    }, [categories])

    // set the relevant values to be used in the meta robots tag
    const indexValue = categories?.c_pageNoIndex ? 'noindex' : 'index'
    const followValue = categories?.c_pageNoFollow ? 'nofollow' : 'follow'

    return (
        <>
            <Helmet>
                <meta name="robots" content={`${indexValue}, ${followValue}`} />
            </Helmet>
            <div>
                {(!isBrowser() || !globalState[paramsCaturl]?.pageStructure) && (
                    <PageTemplateSkeleton
                        layout={
                            location.pathname === '/'
                                ? 'homepage'
                                : location?.state?.skeleton || skeletonCategory?.c_template
                        }
                        skeletonAvailable={
                            location?.state?.skeleton || skeletonCategory?.c_template
                        }
                    />
                )}
                <div
                    className={`catlanding clp-page lay-${cachedPageStructure?.layout} ${
                        cachedPageStructure?.layout?.includes('homepage-new')
                            ? ''
                            : 'category-container'
                    }`}
                >
                    <TabletOrSmaller>
                        {isExpanded && (
                            <div
                                className="full-overlay"
                                onClick={() => setIsExpanded(false)}
                            ></div>
                        )}
                    </TabletOrSmaller>
                    <div
                        className={`catlanding-inner ${
                            cachedPageStructure?.layout?.includes('homepage-new')
                                ? ''
                                : 'content-width'
                        }`}
                    >
                        <div className="catlanding-header content-width">
                            {(!isBrowser() ||
                                paramsCaturl === catUrl ||
                                globalState[paramsCaturl]?.pageStructure) &&
                                typeof cachedCategories !== 'undefined' && (
                                    <h1 className="h1 catlanding-title">
                                        {cachedCategories.c_h1Tag}
                                    </h1>
                                )}
                            <div className={isExpanded ? 'expanded' : ''}>
                                {(!isBrowser() || globalState[paramsCaturl]?.pageStructure) &&
                                    cachedCategories &&
                                    typeof cachedCategories.categories !== 'undefined' &&
                                    cachedCategories.categories.length > 0 &&
                                    cachedCategoryAsset && (
                                        <ViewCategories
                                            categories={cachedCategories.categories}
                                            asset={cachedCategoryAsset.c_body}
                                            parentCategory={cachedCategories.c_h1Tag}
                                            expanded={(open) => setIsExpanded(open)}
                                            closed={isExpanded}
                                        />
                                    )}
                            </div>
                        </div>

                        <div id="primary" className="primary-content">
                            {(!isBrowser() || globalState[paramsCaturl]?.pageStructure) && (
                                <PageTemplateWithData
                                    pageStructure={cachedPageStructure}
                                    title={cachedCategories?.page_title}
                                    category={cachedCategories}
                                    allSlotsData={cachedAllSlotsData}
                                    meta={cachedMeta}
                                    categoryLandingPage={true}
                                    pageDataPushed={pageDataPushed}
                                    canonicalUrl={canonicalUrl}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

CategoryLandingPage.propTypes = {
    errorMessage: PropTypes.string,
    params: PropTypes.object,
    pageStructure: PropTypes.object,
    allSlotsData: PropTypes.any,
    catUrl: PropTypes.string,
    trackPageLoad: PropTypes.func,
    categories: PropTypes.object,
    categoryAsset: PropTypes.object,
    history: PropTypes.any,
    meta: PropTypes.object,
    redirect: PropTypes.bool,
    redirectDetails: PropTypes.any,
    externalRedirect: PropTypes.bool
}

export default CategoryLandingPage
