import React, {Fragment, useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import Link from 'progressive-web-sdk/dist/components/link'
import {useHistory} from 'react-router-dom'

const ViewCategories = ({categories, asset, parentCategory, expanded, closed}) => {
    
    const [expandCategory, setExpandCategory] = useState(false)
    const [clickHandlerAttached, setClickHandlerAttached] = useState(false)
    const contentAssetRef = useRef()
    const history = useHistory()
    useEffect(() => {
        setExpandCategory(closed)
    }, [closed])

    useEffect(() => {
        formatLinks()
    }, [asset])

    const formatLinks = () => {
        let anchor = contentAssetRef.current.getElementsByTagName('a')
        if (anchor.length > 0 && !clickHandlerAttached) {
            Array.from(anchor).forEach((element) => {
                element.onclick = (e) => {
                    e.preventDefault()
                    history.push(`${new URL(element?.href).pathname}`)
                }
                setClickHandlerAttached(true)
            })
        }
    }
    const getPath = (value) => {
        return value
            .replace(/(, | )/g, '-')
            .replace(/&/g, 'and')
            .replace(/(\(|\))/g, '')
            .replace(/\s+/g, '-')
            .replace(/(, | )/g, '-')
            .replace(/&/g, 'and')
            .replace(/,/g, '')
            .replace(/:/g, '')
            .replace(/%/g, '')
            .replace(/(\(|\)|')/g, '')
            .toLowerCase()
    }

    const toggleCategory = (isExpanded) => {
        expanded(isExpanded)
        setExpandCategory(isExpanded)
    }

    return (
        <Fragment>
            <button
                aria-label="view categories"
                className="btn-blue"
                onClick={() => toggleCategory(!expandCategory)}
            >
                View Categories
            </button>
            <div className="top-menu">
                <div className="top-menu-inner">
                    <div className="content-holder-padding">
                        <h3 className="categories-menu-title">Select Category</h3>
                        <button
                            aria-hidden="true"
                            className="close-btn"
                            onClick={() => toggleCategory(false)}
                        >
                            <svg className="icon-close" title="close">
                                <use xlinkHref="#icon-cross">
                                    <svg id="icon-cross" viewBox="0 0 18 18">
                                        <path
                                            id="ic_cross"
                                            className="cls-1"
                                            d="M1374.12 28.991l-2.11-2.116 15.87-15.869 2.11 2.116zm-2.11-15.869l2.11-2.116 15.87 15.869-2.11 2.116z"
                                            transform="translate(-1372 -11)"
                                        ></path>
                                    </svg>
                                </use>
                            </svg>
                        </button>

                        <ul id="sub-categories-list" className="categories-menu">
                            {categories &&
                                categories.map((el, index) => {
                                    const {name, c_pageUrl, c_alternativeUrl} = el
                                    return (
                                        <li
                                            className="categories-menu-item"
                                            key={`category_${index}`}
                                        >
                                            <Link
                                                className="categories-menu-link"
                                                title={`Go to Category: ${name}`}
                                                href={c_alternativeUrl !== "" && c_alternativeUrl !== undefined ? `${c_alternativeUrl?.toLowerCase()}` : `/${c_pageUrl?.toLowerCase()}/`}
                                                onClick={()=> toggleCategory(false) }
                                            >
                                                {name}
                                            </Link>
                                        </li>
                                    )
                                })}
                        </ul>

                        <div
                            ref={contentAssetRef}
                            className="content-asset"
                            dangerouslySetInnerHTML={{__html: asset}}
                        ></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ViewCategories.propTypes = {
    categories: PropTypes.array,
    asset: PropTypes.string,
    parentCategory: PropTypes.string,
    expanded: PropTypes.func
}

export default ViewCategories
