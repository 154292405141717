export const metaTagsProductList = ({
    category,
    metaDescription,
    metaTitle,
    productList,
    location,
    pageNumber
}) => {
    // set the relevant values to be used in the meta robots tag
    const indexValue = category?.c_pageNoIndex ? 'noindex' : 'index'
    const followValue = category?.c_pageNoFollow ? 'nofollow' : 'follow'

    return [
        {name: 'twitter:card', content: process.env.TWITTER_CARD},
        {name: 'twitter:site', content: process.env.TWITTER_SITE},
        {name: 'twitter:creator', content: process.env.TWITTER_CREATOR},
        {
            name: 'twitter:title',
            content: metaTitle
        },
        {
            name: 'twitter:description',
            content: metaDescription
        },
        {
            name: 'twitter:image',
            content:
                productList?.[pageNumber] &&
                productList?.[pageNumber]?.hits &&
                productList?.[pageNumber]?.hits?.length > 0
                    ? productList?.[pageNumber]?.hits[0].c_images &&
                      productList?.[pageNumber]?.hits[0].c_images.length > 0 &&
                      productList?.[pageNumber]?.hits[0].c_images[0].url
                        ? productList?.[pageNumber]?.hits[0].c_images[0].url
                        : process.env.TWITTER_IMAGE
                    : process.env.TWITTER_IMAGE
        },
        {
            name: 'robots',
            content: `${indexValue}, ${followValue}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`
        },
        {
            name: 'description',
            itemprop: 'description',
            content: metaDescription
        },
        {name: 'keywords', itemprop: 'keywords', content: process.env.META_KEYWORDS},
        {
            property: 'og:image',
            content:
                productList &&
                productList?.[pageNumber]?.hits &&
                productList?.[pageNumber]?.hits?.length > 0
                    ? productList?.[pageNumber]?.hits[0].c_images &&
                      productList?.[pageNumber]?.hits[0].c_images.length > 0 &&
                      productList?.[pageNumber]?.hits[0].c_images[0].url
                        ? productList?.[pageNumber]?.hits[0].c_images[0].url
                        : process.env.TWITTER_TITLE
                    : process.env.TWITTER_TITLE
        },
        {property: 'og:type', content: process.env.OG_TYPE},
        {property: 'og:site_name', content: process.env.OG_SITE_NAME},
        {
            property: 'og:title',
            content: metaTitle
        },
        {
            property: 'og:url',
            content: `https://www.whsmith.co.uk${
                location && location.pathname ? location.pathname : process.env.OG_URL
            }`
        },
        {
            property: 'og:description',
            content: metaDescription
        }
    ]
}
