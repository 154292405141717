import React from 'react'
import Link from 'progressive-web-sdk/dist/components/link'
import {useLocation, useHistory} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'
import {Link as WHSLink} from 'react-router-dom'

const Breadcrumb = (props) => {
    const {breadcrumb, isSearchListing, goToHref, setLoading} = props
    const history = useHistory()
    return (
        <div className="breadcrumbs">
            <Helmet
                htmlAttributes={{
                    lang: 'en-US',
                    'data-reactroot': ''
                }}
            >
                <script type="application/ld+json">
                    {`{ 
                    "@context":"https://schema.org", 
                    "@graph":[
                        {
                        "@type": "BreadcrumbList",
                        "@id": "https://www.whsmith.co.uk/#breadcrumb", 
                        "itemListElement": ${JSON.stringify([
                            {
                                '@type': 'ListItem',
                                position: 0,
                                name: 'Home',
                                item: 'https://www.whsmith.co.uk'
                            },
                            ...(breadcrumb?.parent_category_tree?.map((el, count) => {
                                let url = 'https://www.whsmith.co.uk'
                                url += `/${el.c_pageUrl?.toLowerCase()}`
                                return {
                                    '@type': 'ListItem',
                                    position: count + 1,
                                    name: el.name,
                                    item: `${url}/`
                                }
                            }) || [])
                        ])}
                        }]
                    } 
                `}
                </script>
            </Helmet>
            <ul
                className="breadcrumb content-width content-holder-padding"
                itemScope=""
                itemType="https://schema.org/BreadcrumbList"
            >
                {typeof breadcrumb.parent_category_tree !== 'undefined' &&
                    breadcrumb.parent_category_tree.length > 0 &&
                    breadcrumb.parent_category_tree.map((child, count) => {
                        return count < breadcrumb.parent_category_tree.length - 1 ? (
                            <li
                                className="breadcrumb-element"
                                itemProp="itemListElement"
                                itemScope=""
                                itemType="https://schema.org/ListItem"
                                key={breadcrumb.parent_category_tree[count].id}
                            >
                                {count > 0 ? (
                                    <svg className="icon-arrow breadcrumb-next-icon">
                                        <use xlinkHref="#icon-arrow"></use>
                                    </svg>
                                ) : null}
                                <span className="slp-string">
                                    {isSearchListing && count == 0 ? 'Search Results Within' : ''}
                                </span>
                                <WHSLink
                                    to={goToHref({
                                        pathname: `/${child.c_pageUrl?.toLowerCase()}/`,
                                        state: {
                                            skeleton: child.c_template
                                        }
                                    })}
                                    onClick={() => setLoading(true)}
                                >
                                    <div
                                        className="breadcrumb-link"
                                        // params={{ testvalue: "hello" }}
                                        rel="follow"
                                        itemProp="item"
                                        // href={`/${child.c_pageUrl?.toLowerCase()}/`}
                                        title={'Go to ' + child.name}
                                    >
                                        <span itemProp="name">{child.name}</span>
                                    </div>
                                </WHSLink>

                                <meta itemProp="position" content="1" />
                            </li>
                        ) : null
                    })}
                {breadcrumb.parent_category_tree !== undefined &&
                    breadcrumb.parent_category_tree.length > 0 && (
                        <li
                            className="breadcrumb-element"
                            itemProp="itemListElement"
                            itemScope=""
                            itemType="https://schema.org/ListItem"
                        >
                            {breadcrumb.parent_category_tree.length === 1 ? (
                                <span className="breadcrumb-leaf">
                                    <span>Search Results Within</span>
                                </span>
                            ) : (
                                <svg className="icon-arrow breadcrumb-next-icon">
                                    <use xlinkHref="#icon-arrow"></use>
                                </svg>
                            )}
                            <span className="slp-string"></span>
                            <span className="breadcrumb-leaf" style={{marginLeft: '20px'}}>
                                {breadcrumb.name}
                            </span>
                            <meta itemProp="name" content={breadcrumb.name} />
                            <meta
                                itemProp="item"
                                content={`/${breadcrumb.c_pageUrl?.toLowerCase()}/`}
                            />
                            <meta itemProp="position" content="3" />
                        </li>
                    )}
            </ul>
        </div>
    )
}

Breadcrumb.propTypes = {
    breadcrumb: PropTypes.any,
    isSearchListing: PropTypes.bool,
    queryString: PropTypes.string
}

export default Breadcrumb
