/* eslint-disable import/namespace */
/* eslint-disable import/named */
import React, {useEffect, useState, useContext} from 'react'
import {useHistory, useLocation, useParams, Link as WHSLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import fetch from 'cross-fetch'
import {getAnalyticsManager} from '../../analytics'
import {PAGEVIEW, product} from 'progressive-web-sdk/dist/analytics-integrations/types'
import {getContentAsset, getContentAssets} from '../../components/content-asset/helper'
import {getAllMenuData} from '../../components/menu/helper'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import {callSupplierPrecedence} from '../../pages/isomorphic/globalFunctions'
import {generateEinsteinRecommender} from '../../utils/utils'
import {
    getProducts,
    getProductsFromCategory,
    getMetaFallbackRules
} from '../../components/faceout/helper'
import {pushDatalayerEvent, breadcrumbListPLP, uniq, pageView, isBrowser} from '../../utils/utils'
import ProductListPageTemplate from '../../components/product-list-page-template'
import {findCategoryBySlug, findCategoryById} from '../isomorphic/globalFunctions'
import CategoryLandingPage from '../category-landing'
import CustomHelmet from './CustomHelmet'
import {
    DEFAULT_SORTING_OPTIONS,
    EINSTEIN_RECOMMENDATION_ZONES,
    PAGE_TITLES
} from '../../utils/constants'
import {getFromLocal, getFromSession, getCookie} from '../../utils/storage-utils'
import {createQueryUrl, createApiQueryUrl, fetchRedirectDetails, validURL} from './helper'
import {HTTPError} from 'progressive-web-sdk/dist/ssr/universal/errors'
import {
    GlobalDispatchContext,
    GlobalStateContext,
    SET_CATEGORIES,
    SET_CATEGORY_PRODUCT_LIST,
    SET_CATEGORY_PRODUCT_DATA,
    SET_CATEGORY_MASTER_LIST
} from '../../components/global-state'
import {GET_ENV} from '../../connector'
import PageTemplateSkeleton from '../home/skeleton'
import Button from 'progressive-web-sdk/dist/components/button'
import {metaTagsProductList} from './meta'
import {buildProductDataForDataLayer, addSearchEvent} from '../../utils/gtmUtils'
import {useEinstein} from '../../hooks/useEinstein'
import {useBloomreach} from '../../hooks/useBloomreach'

const ProductList = (props) => {
    const {
        allSlotsData,
        categories,
        categoryAsset,
        errorMessage,
        isSearchListing,
        productListPageStructure,
        queryCategory,
        queryCategoryName,
        queryString,
        meta,
        meta_fallback_rules,
        pathnameGetProps,
        productListRedirect = false,
        redirectDetails,
        externalRedirect = false,
        redirectToCanonical = false,
        apiTimedOut = false,
        canonicalUrl = '',
        productListTemp
    } = props

    const history = useHistory()
    const location = useLocation()

    const [loading, setLoading] = useState(true)
    const [refinementLoading, setRefinementLoading] = useState(false)
    const [sortingOptions, setSortingOptions] = useState([])
    const [sortingObjects, setSortingObjects] = useState({})
    const [refinements, setRefinements] = useState([])
    const [refinedItems, setReFinedItems] = useState([])
    const [analyticsPushStatus, setAnalyticsPushStatus] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [priceLabel, setPriceLabel] = useState('')
    const [perPageItemsValue, setPerPageItemsValue] = useState()
    const [selectedSortedValue, setSelectedSortedValue] = useState()
    const [oldUrl, setOldUrl] = useState('')
    const [pageViewPushed, setPageViewPushed] = useState(false)
    const [viewItemListPushed, setViewItemListPushed] = useState(false)
    const [searchEventPushed, setSearchEventPushed] = useState(false)
    const analyticsManager = getAnalyticsManager()
    const productsPerPageOptions = [12, 24, 36, 48, 60]
    let params = useParams()
    const categoryUrl = params.catUrl
    let {productList, productData, category = {}, masterDataArray} = props
    let pageNumber = isBrowser() ? 0 : props.pageNumber
    let totalProducts = productList?.[pageNumber]?.total || 0
    const dispatch = useContext(GlobalDispatchContext)
    const globalState = useContext(GlobalStateContext)
    const queryData = new URLSearchParams(location.search)
    let query = ''

    if (location.search) {
        query = new URLSearchParams(location.search)
        pageNumber = query.has('p') ? parseInt(query?.get('p')) : 0
        query.delete('p')
        query.delete('retry')
    }

    // create an instance of einstein
    const einstein = useEinstein()

    // create instance of bloomreach
    const bloomreach = useBloomreach()

    // send the einstein event
    useEffect(() => {
        if (productData) {
            category?.name
                ? einstein.sendViewCategory(category, {
                      hits: productData[pageNumber]
                  })
                : einstein.sendViewSearch(queryString, {
                      hits: productData[pageNumber]
                  })
        }
    }, [einstein.einsteinEnabled, productData, category, queryString])

    // then the bloomreach event
    useEffect(() => {
        if (
            productData &&
            category?.name &&
            productListTemp &&
            bloomreach.bloomreachEnabled &&
            !loading
        ) {
            bloomreach.viewCategory(
                category,
                productListTemp.hits,
                refinedItems,
                selectedSortedValue
            )
        }
    }, [bloomreach.bloomreachEnabled, productListTemp, category, loading])

    useEffect(() => {
        const locationSearch = new URLSearchParams(location.search)
        if (
            redirectToCanonical &&
            (locationSearch.has('start') || locationSearch.has('p') || locationSearch.has('sz')) &&
            !(globalState?.categoryProductData?.[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ] || {})[pageNumber]
        ) {
            let pathname = location.pathname

            let queryString = new URLSearchParams(location.search)
            queryString.delete('p')
            queryString.delete('start')
            queryString.delete('sz')

            history.replace({
                pathname,
                ...(isSearchListing
                    ? {
                          search: createQueryUrl(undefined, queryString.toString(), true)
                      }
                    : {})
            })
        }
    }, [redirectToCanonical])

    let skeletonCategory = {}
    globalState?.menuCategories?.some((cat) => {
        let foundCategory = findCategoryBySlug(cat, `${location?.pathname}${location?.search}`)
        if (foundCategory) {
            skeletonCategory = foundCategory
            return true
        }
    })

    useEffect(() => {
        setReFinedItems([])
        return () => {
            setSelectedSortedValue()
            setPerPageItemsValue(productData?.[pageNumber]?.length)
            setSortingOptions()
            setReFinedItems([])
        }
    }, [location.pathname])

    if (
        globalState?.categoryProductData &&
        globalState?.categoryProductData[
            `${location.pathname}${query.toString() && '?' + query.toString()}`
        ]
    ) {
        productData =
            globalState?.categoryProductData[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ]
        productList =
            globalState?.categoryProductList?.[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ]
        totalProducts = productList?.[pageNumber]?.total
        masterDataArray =
            globalState?.masterData[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ]
        if (
            globalState?.categories[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ]
        ) {
            category =
                globalState?.categories[
                    `${location.pathname}${query.toString() && '?' + query.toString()}`
                ]
        }
    }

    useEffect(() => {
        if (
            !queryData.has('srule') &&
            (!selectedSortedValue ||
                (selectedSortedValue &&
                    productList?.[pageNumber]?.sorting_options?.[0]?.label !== selectedSortedValue))
        ) {
            setSelectedSortedValue(productList?.[pageNumber]?.sorting_options?.[0]?.label)
        } else if (
            queryData.has('srule') &&
            (!selectedSortedValue ||
                selectedSortedValue !==
                    productList?.[pageNumber]?.sorting_options?.filter(
                        (obj) => obj.id === queryData.get('srule')
                    )[0]?.label)
        ) {
            setSelectedSortedValue(
                productList?.[pageNumber]?.sorting_options?.filter(
                    (obj) => obj.id === queryData.get('srule')
                )[0]?.label
            )
        }
    }, [location.search, productList])

    useEffect(() => {
        if (productData?.[pageNumber]?.length) {
            setRefinementLoading(false)
            setPerPageItemsValue(productData?.[pageNumber]?.length)
        }
    }, [productData?.[pageNumber]])

    useEffect(() => {
        if (
            isSearchListing &&
            !searchEventPushed &&
            !analyticsPushStatus &&
            queryString !== '' &&
            Object.keys(productList).length > 0 &&
            location.pathname !== '/no-results-found/'
        ) {
            pageView(PAGE_TITLES.SEARCH, 'search-list-page', 'Search', '', () => {
                addSearchEvent('search', queryString, location.pathname, productList?.[0].total)
            })
            setSearchEventPushed(true)
        }
    }, [isSearchListing, queryString, analyticsPushStatus, productData])

    useEffect(() => {
        if (
            apiTimedOut &&
            props.showSkeleton === false &&
            Object.values(
                globalState?.categoryProductList?.[
                    `${location.pathname}${query.toString() && '?' + query.toString()}`
                ] || {}
            ).length
        ) {
            dispatch({
                type: SET_CATEGORY_PRODUCT_LIST,
                payload: {
                    [`${location.pathname}${query.toString() && '?' + query.toString()}`]: {
                        ...globalState?.categoryProductList?.[
                            `${location.pathname}${query.toString() && '?' + query.toString()}`
                        ],
                        [pageNumber]: {
                            ...(globalState?.categoryProductList?.[
                                `${location.pathname}${query.toString() && '?' + query.toString()}`
                            ]?.[pageNumber] || {}),
                            showSkeleton: false
                        }
                    }
                }
            })
        } else if (
            apiTimedOut &&
            !Object.values(
                globalState?.categoryProductList?.[
                    `${location.pathname}${query.toString() && '?' + query.toString()}`
                ] || {}
            ).length &&
            (pathnameGetProps
                ?.replace(/\//g, '')
                ?.includes(
                    `${location.pathname}${query.toString() && '?' + query.toString()}`?.replace(
                        /\//g,
                        ''
                    )
                ) ||
                pathnameGetProps?.replace(/\//g, '') ===
                    `${location.pathname}${query.toString() && '?' + query.toString()}`?.replace(
                        /\//g,
                        ''
                    ))
        ) {
            setLoading(false)
        }
    }, [apiTimedOut])

    const handleTimeoutSkeleton = () => {
        dispatch({
            type: SET_CATEGORY_PRODUCT_LIST,
            payload: {
                [`${location.pathname}${query.toString() && '?' + query.toString()}`]: {
                    ...globalState?.categoryProductList?.[
                        `${location.pathname}${query.toString() && '?' + query.toString()}`
                    ],
                    [pageNumber]: {
                        ...(globalState?.categoryProductList?.[
                            `${location.pathname}${query.toString() && '?' + query.toString()}`
                        ]?.[pageNumber] || {}),
                        showSkeleton: true
                    }
                }
            }
        })
    }

    useEffect(() => {
        if (
            globalState?.categoryProductData?.[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ]?.[pageNumber]
        ) {
            setLoading(false)
        }
    }, [`${location.pathname}${query.toString() && '?' + query.toString()}`])

    useEffect(() => {
        if (productListRedirect) {
            if (externalRedirect) {
                window.open(redirectDetails, '_blank')
            } else history.replace(redirectDetails)
        }
    }, [productListRedirect, redirectDetails])

    useEffect(() => {
        const startValue = parseInt(queryData?.get('p') || 0) * process.env.PRODUCT_SEARCH_COUNT
        const producttempIDs =
            (globalState?.categoryProductData?.[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ] || [])?.[pageNumber] || []
        if (
            (category?.id || isSearchListing) &&
            props?.productData?.[pageNumber]?.length > 0 &&
            props?.productList?.[pageNumber]?.start == startValue &&
            (pathnameGetProps
                ?.replace(/\//g, '')
                ?.includes(
                    `${location.pathname}${query.toString() && '?' + query.toString()}`?.replace(
                        /\//g,
                        ''
                    )
                ) ||
                pathnameGetProps?.replace(/\//g, '') ===
                    `${location.pathname}${query.toString() && '?' + query.toString()}`?.replace(
                        /\//g,
                        ''
                    )) &&
            (/\/$/.test(location.pathname) || isSearchListing) &&
            producttempIDs.filter(
                (itm) => props?.productData?.[pageNumber].map((el) => el.id).indexOf(itm.id) > -1
            )?.length !== props?.productData?.[pageNumber].map((el) => el.id).length &&
            ((query.toString().length === 0 && !pathnameGetProps?.includes('?')) ||
                (query.toString().length > 0 && pathnameGetProps?.includes('?')))
        ) {
            const previousProductData =
                globalState?.categoryProductData?.[
                    `${location.pathname}${query.toString() && '?' + query.toString()}`
                ]?.[pageNumber - 1]
            const previousProductList =
                globalState?.categoryProductList?.[
                    `${location.pathname}${query.toString() && '?' + query.toString()}`
                ]?.[pageNumber - 1]
            const previousMasterDataArray =
                globalState?.masterData?.[
                    `${location.pathname}${query.toString() && '?' + query.toString()}`
                ]?.[pageNumber - 1]
            dispatch({
                type: SET_CATEGORY_PRODUCT_DATA,
                payload: {
                    [`${location.pathname}${query.toString() && '?' + query.toString()}`]: {
                        ...globalState?.categoryProductData?.[
                            `${location.pathname}${query.toString() && '?' + query.toString()}`
                        ],
                        [pageNumber]: [
                            ...(previousProductData || []),
                            ...props?.productData[pageNumber]
                        ],
                        [pageNumber - 1]: [],
                        [pageNumber + 1]: [
                            ...(previousProductData || []),
                            ...props?.productData[pageNumber]
                        ]
                    }
                }
            })
            dispatch({
                type: SET_CATEGORY_MASTER_LIST,
                payload: {
                    [`${location.pathname}${query.toString() && '?' + query.toString()}`]: {
                        ...globalState?.masterData?.[
                            `${location.pathname}${query.toString() && '?' + query.toString()}`
                        ],
                        [pageNumber]: [
                            ...(previousMasterDataArray || []),
                            ...props?.masterDataArray[pageNumber]
                        ],
                        [pageNumber - 1]: [],
                        [pageNumber + 1]: [
                            ...(previousMasterDataArray || []),
                            ...props?.masterDataArray[pageNumber]
                        ]
                    }
                }
            })
            dispatch({
                type: SET_CATEGORY_PRODUCT_LIST,
                payload: {
                    [`${location.pathname}${query.toString() && '?' + query.toString()}`]: {
                        ...globalState?.categoryProductList?.[
                            `${location.pathname}${query.toString() && '?' + query.toString()}`
                        ],
                        [pageNumber]: {
                            ...props?.productList?.[pageNumber],
                            hits: [
                                ...(previousProductList?.hits || []),
                                ...props?.productList?.[pageNumber].hits
                            ],
                            count:
                                parseInt(previousProductData?.count || 0) +
                                parseInt(props?.productList?.[pageNumber].count),
                            showSkeleton: false
                        },
                        [pageNumber - 1]: {},
                        [pageNumber + 1]: {
                            ...props?.productList?.[pageNumber],
                            hits: [
                                ...(previousProductList?.hits || []),
                                ...props?.productList?.[pageNumber].hits
                            ],
                            count:
                                parseInt(previousProductData?.count || 0) +
                                parseInt(props?.productList?.[pageNumber].count),
                            showSkeleton: true
                        },
                        query: query && query.has('q') && query.get('q')
                    }
                }
            })
            if (category?.id) {
                dispatch({
                    type: SET_CATEGORIES,
                    payload: {
                        [`${location.pathname}${query.toString() &&
                            '?' + query.toString()}`]: category
                    }
                })
            }
            setLoading(false)
        }
    }, [category?.id, JSON.stringify(props.productData?.[pageNumber] || []), category])

    useEffect(() => {
        let qStatus = analyticsPushStatus || false
        if (analyticsPushStatus && searchQuery !== queryString) {
            setAnalyticsPushStatus(false)
            qStatus = false
        }
        const analyticBreadcrumb = breadcrumbListPLP(isSearchListing, queryString, category) //breadcrumbArray()

        let analyticbasketInfo = ''
        if (isBrowser()) {
            analyticbasketInfo = getFromLocal('analyticBasket')
            if (analyticbasketInfo != '') {
                analyticbasketInfo = JSON.parse(analyticbasketInfo)
            } else {
                analyticbasketInfo = {
                    cartValue: 0,
                    cartItemsNum: 0,
                    cartUniqueItemsNum: 0
                }
            }
        } else {
            analyticbasketInfo = {
                cartValue: 0,
                cartItemsNum: 0,
                cartUniqueItemsNum: 0
            }
        }

        const analyticData = {}

        if (isSearchListing && queryCategoryName) {
            analyticData.event = 'searchResults'
            analyticData.templateName = 'search-results-page'
            analyticData.pageType = 'searchResults'
            analyticData.categoryID = category?.id || queryCategoryName
            analyticData.categoryName = queryCategoryName || category?.id
            analyticData.currencyCode = 'GBP'
            analyticData.numberResults =
                productData?.[pageNumber] && productData?.[pageNumber].length
            analyticData.searchKeyword = queryString
        } else if (category && category.hasOwnProperty('name')) {
            analyticData.event = 'pageDataPushed'
            analyticData.templateName = categoryUrl === 'charts' ? 'chart-page' : 'category-page'
            analyticData.pageType = categoryUrl === 'charts' ? 'chart' : 'category'
            analyticData.categoryID = category.id
            analyticData.categoryName = category.name
        } else {
            analyticData.templateName = categoryUrl === 'charts' ? 'chart-page' : 'category-page'
            analyticData.pageType = categoryUrl === 'charts' ? 'chart' : 'category'
        }
        if (Object.keys(analyticBreadcrumb).length > 0) {
            analyticData['breadcrumb'] = analyticBreadcrumb
        }
        if (isBrowser()) {
            analyticData.pageURL = window.location.href
            analyticData.visitorStatus =
                getCookie('isUserLoggedIn') && getCookie('isUserLoggedIn') === 'true'
                    ? 'regular-logged'
                    : 'guest'
            analyticData.userId = getCookie('customerId') ? getCookie('customerId') : null
        }
        analyticData.cartValue = analyticbasketInfo.cartValue
        analyticData.cartItemsNum = analyticbasketInfo.cartItemsNum
        analyticData.cartUniqueItemsNum = analyticbasketInfo.cartUniqueItemsNum
        analyticData.pageTitle =
            meta?.title ||
            (isSearchListing
                ? process.env.SEARCH_PAGE_TITLE
                : category && category.page_title
                ? category.page_title
                : process.env.PAGE_TITLE)
        analyticData.originalLocation =
            getFromSession('originalLocation') !== ''
                ? getFromSession('originalLocation')
                : window.location.href

        if (!qStatus) {
            if (analyticData?.event === 'pageDataPushed') {
                analyticsManager.track(PAGEVIEW, analyticData)
                setAnalyticsPushStatus(true)
            }
            if (analyticData?.event === 'searchResults') {
                const analyticNewData = Object.assign({}, analyticData)
                analyticNewData.event = 'pageDataPushed'
                pushDatalayerEvent(analyticNewData)
                setAnalyticsPushStatus(true)
                setSearchQuery(queryString)
            }
        }
        return () => {
            setAnalyticsPushStatus(false)
        }
    }, [isSearchListing, queryString, category?.id])

    useEffect(() => {
        if (productData?.['0']?.length) {
            const items = []
            productData['0']?.forEach((product, index) => {
                let item = buildProductDataForDataLayer(
                    [{product, skipListName: true}],
                    '',
                    index + 1
                )
                items.push(item[0])
            })

            const singleCategoryName =
                categoryUrl &&
                (categoryUrl.charAt(0).toUpperCase() + categoryUrl.slice(1)).replaceAll('-', ' ')
            const analyticBreadcrumb = breadcrumbListPLP(isSearchListing, queryString, category)

            if (
                !pageViewPushed &&
                !viewItemListPushed &&
                location.pathname !== '/no-results-found/'
            ) {
                const datalayerEvent = {
                    event: 'view_item_list',
                    market: 'UK',
                    timestamp: Date.now(),
                    ecommerce: {
                        item_list_id: category.id || singleCategoryName || '',
                        item_list_name: category.name || singleCategoryName || '',
                        items: items
                    },
                    _clear: true
                }
                const pageTitle =
                    meta?.title ||
                    (isSearchListing
                        ? process.env.SEARCH_PAGE_TITLE
                        : category && category.page_title
                        ? category.page_title
                        : process.env.PAGE_TITLE)
                pageView(pageTitle, 'product-list-page', 'Categories', analyticBreadcrumb, () => {
                    pushDatalayerEvent(datalayerEvent)
                })
                setPageViewPushed(true)
                setViewItemListPushed(true)
            }
        }
    }, [productData])

    useEffect(() => {
        if (
            isSearchListing &&
            productList?.[pageNumber] &&
            productList?.[pageNumber]?.hits &&
            productList?.[pageNumber]?.hits?.length === 0 &&
            productList?.[pageNumber].count === 0
        ) {
            history.replace({
                pathname: '/no-results-found/',
                state: {productList, queryString},
                search: `?cgid=${queryCategory}&category=${queryCategoryName}`
            })
        }
        if (productList && productList?.[pageNumber]?.refinements) {
            setRefinements(productList?.[pageNumber]?.refinements)
            checkRefinements(productList?.[pageNumber]?.refinements, location.search)
        }
    }, [productList])

    //Will check refinements checkboxes based on query string
    const getPriceLabel = (value, priceRefinements) => {
        if (priceLabel) {
            return priceLabel
        }
        const {values = []} = priceRefinements || {values: []}
        const selectedPrice = values.find((el) => el.value === value)
        const {label} = selectedPrice
        return label
    }
    const checkRefinements = (refinements = [], queryString) => {
        const queryParams = new URLSearchParams(queryString)
        const qParamsObj = Object.fromEntries(queryParams)
        const refineKeys = Object.keys(qParamsObj)
        const selectedRefineMents = []
        refinements.forEach((el) => {
            if (el && el.attribute_id) {
                const {attribute_id, label = ''} = el
                const ind = refineKeys.indexOf(attribute_id)
                if (ind !== -1 && attribute_id !== 'cgid') {
                    const refinementValuesString = qParamsObj[attribute_id]
                    const refineValuesArray = refinementValuesString.split('|')
                    if (refineValuesArray.length) {
                        refineValuesArray.forEach((value) => {
                            const obj = {
                                id: attribute_id,
                                include: true,
                                label: attribute_id === 'price' ? getPriceLabel(value, el) : value,
                                name: value,
                                type: label
                            }
                            selectedRefineMents.push(obj)
                        })
                    } else {
                        const labelValue = qParamsObj[attribute_id]
                        const obj = {
                            id: attribute_id,
                            include: true,
                            label:
                                attribute_id === 'price'
                                    ? getPriceLabel(labelValue, el)
                                    : labelValue,
                            name: labelValue,
                            type: label
                        }
                        selectedRefineMents.push(obj)
                    }
                }
            }
        })
        setReFinedItems(selectedRefineMents)
    }

    useEffect(() => {
        let sortingTempObject = {}
        populateSortingOptions(productList?.[pageNumber])
        productList?.[pageNumber]?.sorting_options?.forEach((option) => {
            sortingTempObject[option.label] = option.id
        })
        setSortingObjects(sortingTempObject)
    }, [productList])

    const populateSortingOptions = (data) => {
        let sortingLabels =
            data && data.sorting_options && data.sorting_options.map((el) => el.label)
        sortingLabels && setSortingOptions(sortingLabels)
    }

    const currentValue = (value) => {
        if (
            selectedSortedValue !== value &&
            typeof value !== 'undefined' &&
            sortingObjects[value]
        ) {
            let queryParams = new URLSearchParams(location.search)
            queryParams.delete('p')
            queryParams.set('srule', sortingObjects[value])
            const productsData =
                globalState?.categoryProductData?.[
                    `${location.pathname}${queryParams?.toString() &&
                        '?' + queryParams?.toString()}`
                ] || []
            const getValue = Object.keys(productsData || {})?.filter(
                (el) => productsData[el].length
            )

            const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))

            if (
                typeof productsData?.[maxValue] === 'undefined' ||
                productsData?.[maxValue]?.length === 0
            ) {
                window.scrollTo(0, 0)
                setLoading(true)
                history.push({
                    pathname: location.pathname,
                    search: createQueryUrl(
                        {srule: sortingObjects[value]},
                        location.search,
                        false,
                        false,
                        'p'
                    )
                })
            } else {
                window.scrollTo(0, 0)
                if (maxValue) {
                    queryParams.set('p', maxValue)
                }
                window.scrollTo(0, 0)
                history.push({
                    pathname: location.pathname,
                    search: createQueryUrl(
                        {srule: sortingObjects[value]},
                        queryParams.toString(),
                        false,
                        false
                    )
                })
            }
        }
    }
    const parentCategoryName = category
        ? category.parent_category_tree
            ? category.parent_category_tree[
                  category?.parent_category_tree?.length > 1
                      ? category?.parent_category_tree?.length - 2
                      : 0
              ]
            : ''
        : ''

    const metaDescription =
        (category && category.page_description) ||
        meta_fallback_rules?.category?.description?.replace(/{categoryName}/g, category.name)

    const metaTitle = isSearchListing
        ? process.env.SEARCH_PAGE_TITLE
        : category && category.page_title
        ? category.page_title
        : meta_fallback_rules?.category?.title
              .replace(
                  '{parentCategoryName} |',
                  parentCategoryName.name !== category.name ? `${parentCategoryName.name} |` : ''
              )
              .replace('{categoryName}', category.name)

    const metaTags = metaTagsProductList({
        category,
        metaDescription,
        metaTitle,
        productList,
        location,
        pageNumber
    })

    const handleRefinement = (refinements = []) => {
        setRefinementLoading(true)
        const urlObj = {
            pathname: location.pathname,
            search: ''
        }
        if (refinements.length) {
            setReFinedItems(refinements)
            const refinementObj = {}
            refinements.forEach((item = {}) => {
                const {id, name, label} = item
                if (id === 'price') {
                    setPriceLabel(label)
                }
                refinementObj[id] =
                    Array.isArray(refinementObj[id]) && refinementObj[id].length
                        ? [...refinementObj[id], name]
                        : [name]
            })
            const refinementUrl = createQueryUrl(
                refinementObj,
                location.search,
                isSearchListing,
                true
            )
            // setLoading(true)
            // refinementUrl
            const urlData = new URLSearchParams(refinementUrl)
            const productsData =
                globalState?.categoryProductData?.[
                    `${location.pathname}${urlData.toString() && '?' + urlData.toString()}`
                ] || []

            const getValue = Object.keys(productsData || {})?.filter(
                (el) => productsData[el].length
            )

            const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))
            if (
                typeof productsData?.[maxValue] === 'undefined' ||
                productsData?.[maxValue]?.length === 0
            ) {
                // setLoading(true)
                urlObj.search = refinementUrl
                window.scrollTo(0, 0)
                history.push(urlObj)
            } else {
                window.scrollTo(0, 0)
                if (maxValue) {
                    urlData.set('p', maxValue)
                }
                history.push(`${location.pathname}?${urlData.toString() && urlData.toString()}`)
            }
        } else {
            let queryParams
            if (isSearchListing) {
                queryParams = new URLSearchParams(location.search)
            }
            setReFinedItems([])
            const queryData = queryParams
                ? new URLSearchParams({
                      ...(queryParams.has('q') ? {q: queryParams.get('q')} : {}),
                      ...(queryParams.has('cgid') ? {cgid: queryParams.get('cgid')} : {}),
                      ...(queryParams.has('category')
                          ? {category: queryParams.get('category')}
                          : {})
                  })
                : new URLSearchParams()

            urlObj.search = queryData?.toString()
            const productsData =
                globalState?.categoryProductData?.[
                    `${location.pathname}${queryData?.toString() && '?' + queryData?.toString()}`
                ] || []
            const getValue = Object.keys(productsData || {})?.filter(
                (el) => productsData[el].length
            )

            const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))

            if (
                typeof productsData?.[maxValue] === 'undefined' ||
                productsData?.[maxValue]?.length === 0
            ) {
                window.scrollTo(0, 0)
                history.push(urlObj)
            } else {
                window.scrollTo(0, 0)
                if (maxValue) {
                    queryData.set('p', maxValue)
                }
                history.push(`${location.pathname}?${queryData.toString() && queryData.toString()}`)
            }
        }
    }

    const handleClearAll = () => {
        const urlObj = {
            pathname: location.pathname,
            search: ''
        }
        setReFinedItems([])

        if (isSearchListing) {
            let queryParams = new URLSearchParams(location.search)
            let newQueryParams = new URLSearchParams({
                ...(queryParams.has('q') ? {q: queryParams.get('q')} : {}),
                ...(queryParams.has('cgid') ? {cgid: queryParams.get('cgid')} : {}),
                ...(queryParams.has('category') ? {category: queryParams.get('category')} : {})
            })
            const productsData =
                globalState?.categoryProductData[
                    `${location.pathname}${newQueryParams.toString() &&
                        '?' + newQueryParams.toString()}`
                ] || []

            const getValue = Object.keys(productsData || {})?.filter(
                (el) => productsData[el].length
            )

            const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))

            if (
                typeof productsData?.[maxValue] === 'undefined' ||
                productsData?.[maxValue]?.length === 0
            ) {
                urlObj.search = newQueryParams.toString()
                setLoading(true)
                history.push(urlObj)
            } else {
                if (maxValue) {
                    newQueryParams.set('p', maxValue)
                }
                urlObj.search = newQueryParams.toString()
                history.push(urlObj)
            }
        } else {
            const productsData = globalState?.categoryProductData?.[location.pathname] || []
            const getValue = Object.keys(productsData || {})?.filter(
                (el) => productsData[el].length
            )

            const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))

            if (
                typeof productsData?.[maxValue] === 'undefined' ||
                productsData?.[maxValue]?.length === 0
            ) {
                setLoading(true)
                history.push({
                    pathname: location.pathname
                })
            } else {
                history.push({
                    pathname: location.pathname,
                    search: maxValue ? `p=${maxValue}` : ''
                })
            }
        }
    }
    const handleApplyFilter = () => {
        window.scrollTo(0, 0)
    }

    const fetchLazyContent = (newPaginationPage, retry = false) => {
        const queryParams = new URLSearchParams(location.search)
        newPaginationPage && queryParams.set('p', newPaginationPage)
        if (retry) {
            queryParams.set(
                'retry',
                queryParams.has('retry') ? parseInt(queryParams.get('retry')) + 1 : 0
            )
        } else {
            queryParams.delete('retry')
        }
        let newUrl = `?${queryParams.toString()}`
        return {
            pathname: location.pathname,
            search: newUrl,
            state: {new: 'new', category: category}
        }
    }

    const goToHref = (url) => {
        const productsData = globalState?.categoryProductData?.[url.pathname]
        const getValue = Object.keys(productsData || {})?.filter((el) => productsData[el].length)

        const maxValue = Math.min(...(getValue.length > 0 ? getValue : [0]))

        if (
            typeof productsData?.[maxValue] === 'undefined' ||
            productsData?.[maxValue]?.length === 0
        ) {
            return url
        } else {
            const urlData = new URLSearchParams()
            if (maxValue) {
                urlData.set('p', maxValue)
            }
            return {
                ...url,
                search: urlData.toString()
            }
        }
    }

    const showWarningMessage = () => {
        if (pageNumber === 0) return false
        if (!isBrowser()) {
            return true
        } else {
            return (
                typeof globalState?.categoryProductData?.[
                    `${location.pathname}${query.toString() && '?' + query.toString()}`
                ]?.[-1] !== 'object'
            )
        }
    }
    const goToCanonicalLink = () => {
        return {
            pathname: location.pathname,
            search: createQueryUrl({}, location.search, false, false, 'p')
        }
    }
    const updateLoading = (value) => {
        setLoading(value)
    }
    return !productListPageStructure &&
        !globalState[
            `${typeof location === 'object' ? location.pathname : location}${query.toString() &&
                '?' + query.toString()}`
        ]?.pageStructure ? (
        apiTimedOut &&
        !Object.values(
            globalState?.categoryProductList?.[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ] || {}
        ).length &&
        (pathnameGetProps
            ?.replace(/\//g, '')
            ?.includes(
                `${location.pathname}${query.toString() && '?' + query.toString()}`?.replace(
                    /\//g,
                    ''
                )
            ) ||
            pathnameGetProps?.replace(/\//g, '') ===
                `${location.pathname}${query.toString() && '?' + query.toString()}`?.replace(
                    /\//g,
                    ''
                )) ? (
            <WHSLink
                replace
                to={fetchLazyContent(pageNumber, true)}
                onClick={() => {
                    handleTimeoutSkeleton()
                    setLoading(true)
                }}
            >
                <Button
                    type="button"
                    className="empty-retry pw--tertiary-retry-button"
                    aria-label="cancel"
                >
                    <div className="button-text-style">
                        Oops, Something went wrong. Please click to try again.
                    </div>
                </Button>
            </WHSLink>
        ) : (
            <div className="t-product-list">
                <CustomHelmet metaTags={metaTags} title={metaTitle}>
                    <title>
                        {isSearchListing
                            ? 'Your Search Results | WHSmith'
                            : category && category.page_title
                            ? category.page_title
                            : meta_fallback_rules?.category?.title
                                  .replace(
                                      '{parentCategoryName} |',
                                      parentCategoryName.name !== category.name
                                          ? `${parentCategoryName.name} |`
                                          : ''
                                  )
                                  .replace('{categoryName}', category.name)}
                    </title>
                    {canonicalUrl ? (
                        <link rel="canonical" href={`${canonicalUrl}`} />
                    ) : (
                        <link
                            rel="canonical"
                            href={`${process.env.HOSTNAME}${encodeURI(
                                location.pathname.toLowerCase()
                            )}`}
                        />
                    )}
                </CustomHelmet>
                {pathnameGetProps
                    ?.replace(/\//g, '')
                    ?.includes(
                        `${location.pathname}${query.toString() &&
                            '?' + query.toString()}`?.replace(/\//g, '')
                    ) ||
                (isSearchListing &&
                    query &&
                    query?.has('q') &&
                    JSON.stringify(Object.keys(globalState.categoryProductData || {})).includes(
                        new URLSearchParams(`q=${query.get('q')}`).toString()
                    )) ||
                ((category &&
                    Object.values(category).length > 0 &&
                    // (isSearchListing ||
                    //     (productData?.[pageNumber]?.length > 0 ||
                    //         globalState?.categoryProductData[
                    //             `${location.pathname}${query.toString() && '?' + query.toString()}`
                    //         ]?.[pageNumber]?.length > 0)) &&
                    (category?.id?.toLowerCase() === params?.categoryId?.toLowerCase() ||
                        `/${category?.c_pageUrl?.toLowerCase()}/` ===
                            (typeof location === 'string' ? location : location.pathname))) ||
                    (query &&
                        query?.has('q') &&
                        globalState?.categoryProductList?.[
                            `${location.pathname}${query.toString() && '?' + query.toString()}`
                        ]?.query === query.get('q'))) ? (
                    <ProductListPageTemplate
                        showCharts={categoryUrl === 'charts'}
                        categoryContentAssetId={`ca-${category?.id}`}
                        categoryContentAssetId2={`cb-${category?.id}`}
                        loaded={!loading}
                        pageTitle={category?.page_title}
                        fetchLazyContent={fetchLazyContent}
                        goToHref={goToHref}
                        pageAssetId={category?.id}
                        data={category}
                        category={category}
                        errorMessage={errorMessage}
                        sortingOptions={sortingOptions}
                        currentValue={currentValue}
                        refinements={refinements}
                        productsPerPageOptions={productsPerPageOptions}
                        selectedSortedValue={selectedSortedValue}
                        productData={productData?.[pageNumber]}
                        masterDataArray={masterDataArray?.[pageNumber]}
                        showSkeleton={productList?.[pageNumber]?.showSkeleton}
                        refinedItems={refinedItems}
                        setReFinedItems={handleRefinement}
                        isSearchListing={isSearchListing}
                        queryString={queryString}
                        queryCategory={queryCategory}
                        queryCategoryName={queryCategoryName}
                        perPageItemsValue={perPageItemsValue}
                        paginationPage={pageNumber}
                        onClearAll={handleClearAll}
                        pageDataPushed={analyticsPushStatus}
                        setLoading={updateLoading}
                        onApplyFilter={handleApplyFilter}
                        refinementLoading={refinementLoading}
                        totalProducts={totalProducts}
                        oldUrl={oldUrl}
                        setOldUrl={setOldUrl}
                        showViewMore={productList?.[pageNumber]?.next}
                        pageNumber={pageNumber}
                        showWarningMessage={showWarningMessage()}
                        handleTimeoutSkeleton={handleTimeoutSkeleton}
                        goToCanonicalLink={goToCanonicalLink}
                        apiTimedOut={apiTimedOut}
                    />
                ) : (
                    <PageTemplateSkeleton
                        layout={location.pathname === '/' ? 'homepage' : location?.state?.skeleton}
                        skeletonAvailable={location?.state?.skeleton}
                    />
                )}
            </div>
        )
    ) : (
        <CategoryLandingPage
            pageStructure={productListPageStructure}
            allSlotsData={allSlotsData}
            categories={categories}
            categoryAsset={categoryAsset}
            meta={meta}
            catUrl={pathnameGetProps ? pathnameGetProps?.split('?')[0] : ''}
            loaded={true}
            skeletonCategory={skeletonCategory}
            canonicalUrl={canonicalUrl}
        />
    )
}

ProductList.getTemplateName = () => {
    return 'product-list'
}

ProductList.shouldGetProps = (props) => {
    const {previousParams, params, previousLocation, location} = props

    return (
        !previousParams ||
        previousParams?.categoryId !== params.categoryId ||
        previousLocation?.search !== location.search ||
        previousParams?.catUrl !== params.catUrl ||
        previousParams?.subCategory !== params.subCategory ||
        previousParams?.subCategory1 !== params.subCategory1
    )
}

ProductList.getProps = async (props) => {
    const {params, connector, req, location, res} = props
    let productListTemp
    if (GET_ENV === 'production' && res) {
        res.set('cache-control', `max-age=86400, s-maxage=86400`)
    }
    const meta_fallback_rules = await getMetaFallbackRules(connector)
    let {subCategory1 = '', subCategory = '', catUrl, categoryId = ''} = params

    const tempCategoryId = categoryId
    let query = ''
    let retry = 0
    if ((req?.query && Object.values(req?.query || {}).length > 0) || location?.search) {
        query = new URLSearchParams(req?.query || location.search)

        //Temporary code to get retry button
        // if (!query.toString().length) {
        //     return {
        //         apiTimedOut: 1,
        //         showSkeleton: false,
        //         pathnameGetProps:
        //             typeof location === 'string'
        //                 ? `${location}${query.toString() && '?' + query.toString()}`
        //                 : `${location.pathname}${query.toString() && '?' + query.toString()}`
        //     }
        // }

        retry = query.has('retry') ? parseInt(query.get('retry')) : 0
        query.delete('p')
        query.delete('retry')
    }

    if (location?.state?.new !== 'new') {
        // Check If we have a manual redirect in place
        let redirectDetails = await fetchRedirectDetails(
            connector,
            (typeof location === 'string' ? location : location.pathname)?.toLowerCase()
        )
        if (redirectDetails) {
            if (!redirectDetails?.includes(`${process.env.HOSTNAME}`)) {
                return {
                    productListRedirect: true,
                    redirectDetails
                }
            }
            return {
                productListRedirect: true,
                externalRedirect: true,
                redirectDetails
            }
        }
        // Check If we have a manual redirect in place end
    }

    let category = {}
    const maxProductsCount = 8
    if (!(subCategory || catUrl) && !categoryId) {
        categoryId = 'cha00000' // Charts Page Default Value
    }
    let categoryData = false
    let categroyExist = false
    let queryParams = new URLSearchParams()
    let categoryDataStructure
    if (req && Object.keys(req?.query).length) {
        queryParams = new URLSearchParams(req.query)
    } else if (location?.search?.length) {
        queryParams = new URLSearchParams(location.search)
    }

    // This will support OLD URL's
    if (
        queryParams.has('start') ||
        queryParams.has('sz') ||
        (queryParams.has('p') && !/^\d+$/.test(queryParams.get('p')))
    ) {
        return {redirectToCanonical: true}
    }

    if (location?.state?.new !== 'new' || !location?.state?.category?.id) {
        if (
            categoryData &&
            categoryData.fault &&
            categoryData.fault.type === 'SiteOfflineException'
        ) {
            throw new HTTPError(503)
        }
        if (!categoryData || categoryData?.fault?.type === 'CategoryNotFoundException') {
            const menuCategories = await getAllMenuData(connector, 'root', 5)
            menuCategories.some((cat) => {
                let foundCategory = findCategoryBySlug(
                    cat,
                    (typeof location === 'string' ? location : location.pathname).toLowerCase()
                )
                if (foundCategory) {
                    categoryId = foundCategory?.id?.toLowerCase()
                    category = foundCategory
                    categroyExist = true
                    return true
                }
            })
        } else {
            categoryId = categoryData?.id?.toLowerCase()
            category = categoryData
            categroyExist = true
        }

        if (
            catUrl?.toLowerCase() === 'stores' ||
            category?.c_pageUrl?.toLowerCase()?.replace(/\//g, '') !==
                (typeof location === 'string' ? location : location.pathname)
                    ?.toLowerCase()
                    ?.replace(/\//g, '')
        ) {
            // check category api data when category is set to be offline
            const categoryTempDataStructure = await getContentAsset(connector, 'mp-homepage')
            if (
                categoryTempDataStructure &&
                categoryTempDataStructure.fault &&
                categoryTempDataStructure.fault.type === 'SiteOfflineException'
            ) {
                throw new HTTPError(503)
            } else {
                throw new HTTPError(404, 'Wrong Page called ')
            }
        }
        if (tempCategoryId && catUrl) {
            categoryDataStructure = await getContentAsset(
                connector,
                `mp-${tempCategoryId?.toUpperCase()}`
            )
            if (categoryDataStructure.fault) {
                categoryDataStructure = await getContentAsset(
                    connector,
                    `mp-${categoryId?.toUpperCase()}`
                )
            }
        } else {
            categoryDataStructure = await getContentAsset(connector, `mp-${catUrl?.toLowerCase()}`)
            if (
                !(
                    categoryDataStructure &&
                    categoryDataStructure !== undefined &&
                    categoryDataStructure._type &&
                    categoryDataStructure.c_body &&
                    categoryDataStructure._type === 'content'
                ) &&
                categoryId
            ) {
                categoryDataStructure = await getContentAsset(
                    connector,
                    `mp-${categoryId?.toUpperCase()}`
                )
            }
        }
    } else {
        category = location?.state?.category
        categoryId = location?.state?.category.id
        categroyExist = true
    }

    // redirect to check when category is set to be offline end
    let canonicalUrl = ''

    if (category.c_canonicalCategoryID) {
        const isUrl = validURL(category.c_canonicalCategoryID)
        if (isUrl) {
            canonicalUrl = category.c_canonicalCategoryID
        } else {
            const menuCategories = await getAllMenuData(connector, 'root', 5)
            let filteredCategory = {}
            menuCategories.some((cat) => {
                let foundCategory = findCategoryById(
                    cat,
                    category?.c_canonicalCategoryID?.toLowerCase()
                )
                if (foundCategory) {
                    filteredCategory = foundCategory
                    return true
                }
            })
            if (Object.values(filteredCategory || {}).length > 0) {
                canonicalUrl = process.env.HOSTNAME + '/' + filteredCategory.c_pageUrl + '/'
            } else {
                canonicalUrl = process.env.HOSTNAME + '/' + category.c_pageUrl.toLowerCase() + '/'
            }
        }
    } else {
        canonicalUrl = process.env.HOSTNAME + '/' + category.c_pageUrl.toLowerCase() + '/'
    }

    if (
        categoryDataStructure &&
        categoryDataStructure !== undefined &&
        categoryDataStructure._type &&
        categoryDataStructure.c_body &&
        categoryDataStructure._type === 'content'
    ) {
        let productListPageStructure = {}
        try {
            productListPageStructure = JSON.parse(categoryDataStructure?.c_body)
        } catch (el) {
            productListPageStructure = {}
        }

        if (productListPageStructure?.contentSlots?.length > 0) {
            const [categoryAsset] = await Promise.all([
                getContentAsset(connector, categoryId?.toUpperCase())
            ])

            let allSlotsData = []
            for (let i = 0; i < productListPageStructure.contentSlots?.length; i++) {
                const slot = productListPageStructure.contentSlots[i]

                if (slot.contentType === 'ContentAsset') {
                    const contentData = await getContentAsset(connector, slot.data)
                    contentData.c_body =
                        (contentData?.c_body || '').replace(
                            /https:\/\/assets.whsmith.co.uk\//g,
                            '/mobify/caching/assets/'
                        ) || contentData.c_body
                    allSlotsData.push({
                        pageStructure: slot,
                        data: contentData
                    })
                }
                if (slot.contentType === 'Recommender') {
                    allSlotsData.push({
                        pageStructure: slot,
                        data: generateEinsteinRecommender(
                            EINSTEIN_RECOMMENDATION_ZONES.CLP,
                            [],
                            [category.id]
                        )
                    })
                }
                if (slot.contentType === 'Faceout') {
                    const products =
                        slot.faceoutType === 'ContentAsset'
                            ? await getContentAssets(connector, slot.data)
                            : await getProducts(connector, slot.data)
                    if (slot.faceoutType === 'ContentAsset') {
                        products?.forEach((product) => {
                            product.c_body =
                                (product?.c_body || '').replace(
                                    /https:\/\/assets.whsmith.co.uk\//g,
                                    '/mobify/caching/assets/'
                                ) || product.c_body
                        })
                        allSlotsData.push({
                            pageStructure: {
                                ...slot,
                                data: slot.data.filter((el) => products.some((e) => e.id === el))
                            },
                            data: {
                                ...products,
                                data:
                                    products?.data?.filter((item, index, self) => {
                                        return self.indexOf(item) === index
                                    }) || []
                            }
                        })
                    } else {
                        let variantDetailsData = []
                        let slotData = []
                        let variantIds = {}
                        for (let i = 0; i < products?.data?.length; i++) {
                            const item = products?.data[i]
                            if (item.price && item.inventory.orderable) {
                                variantIds[item.id] = item?.variants.map((newItem) => {
                                    return newItem.product_id
                                })
                            }
                        }
                        const ids = Object.values(variantIds).flat()

                        let productIndexes = []
                        let index = 1
                        for (let i = 0; i < ids.length; i++) {
                            if (!productIndexes[index - 1]) {
                                productIndexes[index - 1] = []
                            }
                            if (i < index * 23) {
                                productIndexes[index - 1].push(ids[i])
                            } else {
                                productIndexes[index] = []
                                productIndexes[index].push(ids[i])
                                index = index + 1
                            }
                        }
                        let variantDetails = []
                        for (let i = 0; i < productIndexes.length; i++) {
                            const productIds = productIndexes[i]
                            variantDetails = variantDetails.concat(
                                (await getProducts(connector, productIds))?.data || []
                            )
                        }

                        Object.keys(variantIds).map((el) => {
                            const variants = variantDetails?.filter((e) => e.id.includes(el))

                            variantDetailsData = variantDetailsData.concat(
                                callSupplierPrecedence(variants || [])
                            )
                        })
                        slotData = variantDetailsData.map((el) => el.id)
                        variantDetailsData = variantDetailsData.slice(0, maxProductsCount)
                        if (variantDetailsData.length < maxProductsCount) {
                            const categoryResponse = await getProductsFromCategory(
                                connector,
                                slot.categoryId,
                                maxProductsCount - variantDetailsData.length
                            )
                            if (categoryResponse && categoryResponse?.hits) {
                                let newIds = categoryResponse?.hits?.map((e) => {
                                    if (e.product_id && e.c_topPriorityVariation) {
                                        return e.c_topPriorityVariation.id
                                    }
                                })
                                newIds = uniq(newIds)
                                slotData = [...slotData, ...newIds]
                                slotData = uniq(slotData)

                                variantDetailsData =
                                    (await getProducts(connector, slotData))?.data || []
                                variantDetailsData = variantDetailsData.map((item) => {
                                    if (item.price && item.inventory.orderable) return item
                                })
                            }
                        }

                        allSlotsData.push({
                            pageStructure: {...slot, data: slotData},
                            data: variantDetailsData
                        })
                    }
                }
            }

            const meta = {
                type: 'website',
                title:
                    (category && category.page_title) ||
                    meta_fallback_rules?.category?.title
                        .replace('{parentCategoryName} |', '')
                        .replace('{categoryName}', category.name),
                description:
                    (category && category.page_description) ||
                    meta_fallback_rules?.category?.description?.replace(
                        /{categoryName}/g,
                        category.name
                    ),
                image_path: getAssetUrl('static/meta/meta-image-fallback.png'),
                canonicalUrl
            }

            return {
                productListPageStructure,
                allSlotsData,
                categoryAsset,
                categoryId,
                categories: category,
                meta,

                pathnameGetProps:
                    typeof location === 'string'
                        ? `${location}${query.toString() && '?' + query.toString()}`
                        : `${location.pathname}${query.toString() && '?' + query.toString()}`,
                canonicalUrl
            }
        } else {
            let sortingOptions = DEFAULT_SORTING_OPTIONS.product
            if (catUrl === 'charts') {
                sortingOptions = DEFAULT_SORTING_OPTIONS.charts
            }
            if (Object.values(category).length === 0) {
                throw new HTTPError(404)
            }

            if (!queryParams.has('p')) {
                queryParams.set('start', 0)
            } else {
                queryParams.set(
                    'start',
                    parseInt(queryParams.get('p')) * process.env.PRODUCT_SEARCH_COUNT
                )
            }
            if (!queryParams.has('sz')) {
                queryParams.set('sz', process.env.PRODUCT_SEARCH_COUNT)
            }
            if (!queryParams.has('srule') && sortingOptions !== '') {
                queryParams.set('srule', sortingOptions)
            }
            const refinementQString = createApiQueryUrl(
                {
                    ...(!queryParams.has('cgid')
                        ? {
                              cgid: categoryId?.toUpperCase()
                          }
                        : {}),
                    ...(!queryParams.has('price')
                        ? {
                              price: '(0..1000)'
                          }
                        : {})
                },
                queryParams.toString()
            )

            let response = await fetch(
                `${process.env.HOSTNAME}/product-search?q=${''}&${refinementQString}&expand=prices`,
                {
                    headers: {
                        ...connector.client.defaultHeaders,
                        'content-type': 'application/json'
                    },
                    method: 'GET'
                }
            )
            let productList = await response.json()
            productListTemp = await response.json()
            if (productList?.hits?.length > 0) {
                let productData = []
                let masterDataArray = []

                productList?.hits?.map((item) => {
                    let obj = {}
                    let master = {}
                    obj = {...item?.c_topPriorityVariation}
                    obj.c_promotionCustomAttributes =
                        item?.c_topPriorityVariation?.promotionCustomAttributes
                    master.master_id = item.product_id
                    obj.c_images = item.c_images
                    obj.master = master
                    productData.push(obj)
                })
                masterDataArray = productData?.length > 0 ? productData : []
                return {
                    category,
                    productList,
                    productData,
                    masterDataArray,
                    categoryUrl: catUrl,
                    categoryId,
                    pathnameGetProps:
                        typeof location === 'string'
                            ? `${location}${query.toString() && '?' + query.toString()}`
                            : `${location.pathname}${query.toString() && '?' + query.toString()}`,
                    productListPageStructure: null,
                    canonicalUrl
                }
            }

            return {
                category,
                productList,
                subCategory,
                categoryUrl: catUrl,
                categoryId,

                pathnameGetProps:
                    typeof location === 'string'
                        ? `${location}${query.toString() && '?' + query.toString()}`
                        : `${location.pathname}${query.toString() && '?' + query.toString()}`,
                productListPageStructure: null,
                canonicalUrl
            }
        }
    } else {
        let sortingOptions = DEFAULT_SORTING_OPTIONS.product
        const pageNumber = parseInt(queryParams.has('p') ? queryParams.get('p') : 0)
        if (catUrl === 'charts') {
            sortingOptions = DEFAULT_SORTING_OPTIONS.charts
        }

        if (Object.values(category).length === 0) {
            throw new HTTPError(404, 'Category Not Found')
        }

        if (!queryParams.has('p')) {
            queryParams.set('start', 0)
        } else {
            queryParams.set(
                'start',
                parseInt(queryParams.get('p')) * process.env.PRODUCT_SEARCH_COUNT
            )
        }
        if (!queryParams.has('sz')) {
            queryParams.set('sz', process.env.PRODUCT_SEARCH_COUNT)
        }
        if (!queryParams.has('srule') && sortingOptions !== '') {
            queryParams.set('srule', sortingOptions)
        }
        const refinementQString = createApiQueryUrl(
            {
                ...(!queryParams.has('cgid')
                    ? {
                          cgid: categoryId?.toUpperCase()
                      }
                    : {}),
                ...(!queryParams.has('price')
                    ? {
                          price: '(0..1000)'
                      }
                    : {})
            },
            queryParams.toString()
        )

        let productList = {[pageNumber]: {hits: [], count: 0}}
        let productData = {[pageNumber]: []}
        let masterDataArray = {[pageNumber]: []}
        if (location?.state?.new === 'new') {
            const refinementQString = createApiQueryUrl(
                {
                    ...(!queryParams.has('cgid')
                        ? {
                              cgid: categoryId?.toUpperCase()
                          }
                        : {}),
                    ...(!queryParams.has('price')
                        ? {
                              price: '(0..1000)'
                          }
                        : {})
                },
                queryParams.toString()
            )
            let response = await fetch(
                `${process.env.HOSTNAME}/product-search?q=${''}&${refinementQString}&expand=prices`,
                {
                    headers: {
                        ...connector.client.defaultHeaders,
                        'content-type': 'application/json'
                    },
                    method: 'GET'
                }
            )
            try {
                if (response.status == 408 || response.status == 504 || response.status == 500) {
                    return {
                        apiTimedOut: retry + 1 || 1,
                        showSkeleton: false,
                        pathnameGetProps:
                            typeof location === 'string'
                                ? `${location}${query.toString() && '?' + query.toString()}`
                                : `${location.pathname}${query.toString() &&
                                      '?' + query.toString()}`
                    }
                }
                productListTemp = await response.json()
                if (productListTemp?.hits?.length > 0) {
                    productListTemp?.hits?.forEach((item) => {
                        let obj = {}
                        let master = {}
                        obj = {...item?.c_topPriorityVariation}
                        obj.c_promotionCustomAttributes =
                            item?.c_topPriorityVariation?.promotionCustomAttributes
                        master.master_id = item.product_id
                        obj.c_images = item.c_images
                        obj.master = master
                        productData[pageNumber].push(obj)
                        productList?.[pageNumber].hits.push(item)
                    })
                    masterDataArray[pageNumber] =
                        productData[pageNumber]?.length > 0 ? productData[pageNumber] : [] // [...masterProductsResponse.data]
                    productList[pageNumber].count = productList?.[pageNumber]?.hits?.length
                    productList[pageNumber] = {
                        ...productList?.[pageNumber],
                        ...productListTemp
                    }
                } else if (
                    productListTemp?.count === 0 &&
                    productListTemp?.previous?.length &&
                    productListTemp?.total
                ) {
                    return {redirectToCanonical: true}
                } else if (
                    productListTemp?.fault?.type?.length &&
                    /^HookInternalServerErrorException$/.test(productListTemp?.fault?.type)
                ) {
                    return {
                        apiTimedOut: retry + 1 || 1,
                        showSkeleton: false,
                        pathnameGetProps:
                            typeof location === 'string'
                                ? `${location}${query.toString() && '?' + query.toString()}`
                                : `${location.pathname}${query.toString() &&
                                      '?' + query.toString()}`
                    }
                }
            } catch (e) {
                console.error('error', e)
                return {
                    apiTimedOut: retry + 1 || 1,
                    showSkeleton: false,
                    pathnameGetProps:
                        typeof location === 'string'
                            ? `${location}${query.toString() && '?' + query.toString()}`
                            : `${location.pathname}${query.toString() && '?' + query.toString()}`
                }
            }
        } else {
            const pageNumber = parseInt(queryParams.has('p') ? queryParams.get('p') : 0)
            const startValue = pageNumber * process.env.PRODUCT_SEARCH_COUNT
            queryParams.set('start', startValue)
            const refinementQString = createApiQueryUrl(
                {
                    ...(!queryParams.has('cgid')
                        ? {
                              cgid: categoryId?.toUpperCase()
                          }
                        : {}),
                    ...(!queryParams.has('price')
                        ? {
                              price: '(0..1000)'
                          }
                        : {})
                },
                queryParams.toString()
            )
            let response = await fetch(
                `${process.env.HOSTNAME}/product-search?q=${''}&${refinementQString}&expand=prices`,
                {
                    headers: {
                        ...connector.client.defaultHeaders,
                        'content-type': 'application/json'
                    },
                    method: 'GET'
                }
            )
            try {
                if (response.status == 408 || response.status == 504 || response.status == 500) {
                    return {
                        apiTimedOut: retry + 1 || 1,
                        showSkeleton: false,
                        pathnameGetProps:
                            typeof location === 'string'
                                ? `${location}${query.toString() && '?' + query.toString()}`
                                : `${location.pathname}${query.toString() &&
                                      '?' + query.toString()}`
                    }
                }
                productListTemp = await response.json()
                if (productListTemp?.hits?.length > 0) {
                    productListTemp?.hits?.forEach((item) => {
                        let obj = {}
                        let master = {}
                        obj = {...item?.c_topPriorityVariation}
                        obj.c_promotionCustomAttributes =
                            item?.c_topPriorityVariation?.promotionCustomAttributes
                        master.master_id = item.product_id
                        obj.c_images = item.c_images
                        obj.master = master
                        productData[pageNumber].push(obj)
                        productList?.[pageNumber].hits.push(item)
                    })
                    masterDataArray[pageNumber] =
                        productData[pageNumber]?.length > 0 ? productData[pageNumber] : [] // [...masterProductsResponse.data]
                    productList[pageNumber].count = productList?.[pageNumber].hits.length
                    productList[pageNumber].start = startValue
                    productList[pageNumber] = {
                        ...productList?.[pageNumber],
                        ...productListTemp
                    }
                } else if (
                    productListTemp?.count === 0 &&
                    productListTemp?.previous?.length &&
                    productListTemp?.total
                ) {
                    return {redirectToCanonical: true}
                } else if (
                    productListTemp?.fault?.type?.length &&
                    /^HookInternalServerErrorException$/.test(productListTemp?.fault?.type)
                ) {
                    return {
                        apiTimedOut: retry + 1 || 1,
                        showSkeleton: false,
                        pathnameGetProps:
                            typeof location === 'string'
                                ? `${location}${query.toString() && '?' + query.toString()}`
                                : `${location.pathname}${query.toString() &&
                                      '?' + query.toString()}`
                    }
                }
            } catch (error) {
                console.error('error', error)
                return {
                    apiTimedOut: retry + 1 || 1,
                    showSkeleton: false,
                    pathnameGetProps:
                        typeof location === 'string'
                            ? `${location}${query.toString() && '?' + query.toString()}`
                            : `${location.pathname}${query.toString() && '?' + query.toString()}`
                }
            }
        }

        if (productList?.[pageNumber].hits.length > 0) {
            return {
                category,
                productList,
                productData,
                masterDataArray,
                categoryUrl: catUrl,
                categoryId,
                refinementQString,
                meta_fallback_rules,
                pathnameGetProps:
                    typeof location === 'string'
                        ? `${location}${query.toString() && '?' + query.toString()}`
                        : `${location.pathname}${query.toString() && '?' + query.toString()}`,
                productListPageStructure: null,
                pageNumber,
                redirectToCanonical: false,
                apiTimedOut: false,
                showSkeleton: true,
                canonicalUrl,
                productListTemp
            }
        } else {
            let parentCategory =
                category?.parent_category_tree[
                    (category?.parent_category_tree?.length > 1
                        ? category?.parent_category_tree?.length - 2
                        : 0)
                ]
            if (parentCategory) {
                return {
                    productListRedirect: true,
                    redirectDetails: `/${parentCategory.c_pageUrl}/`?.toLowerCase()
                }
            } else throw new HTTPError(404)
        }
    }
}

ProductList.propTypes = {
    allSlotsData: PropTypes.any,
    categories: PropTypes.array,
    category: PropTypes.object,
    categoryAsset: PropTypes.any,
    categoryId: PropTypes.string,
    categoryUrl: PropTypes.string,
    catUrl: PropTypes.string,
    errorMessage: PropTypes.string,
    isSearchListing: PropTypes.bool,
    masterDataArray: PropTypes.array,
    productData: PropTypes.array,
    productList: PropTypes.object,
    productListPageStructure: PropTypes.any,
    queryCategory: PropTypes.string,
    queryCategoryName: PropTypes.string,
    queryPerPage: PropTypes.string,
    queryStart: PropTypes.string,
    queryString: PropTypes.string,
    subCategory: PropTypes.string,
    productListRedirect: PropTypes.bool,
    redirectDetails: PropTypes.any,
    externalRedirect: PropTypes.bool,
    meta: PropTypes.object,
    meta_fallback_rules: PropTypes.object,
    pathnameGetProps: PropTypes.string,
    redirectToCanonical: PropTypes.bool,
    apiTimedOut: PropTypes.bool,
    canonicalUrl: PropTypes.string,
    showSkeleton: PropTypes.bool,
    productListTemp: PropTypes.object
}

export default ProductList
