/* eslint-disable jsx-a11y/rule-name */
import React from 'react'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'
import { isBrowser } from '../../utils/utils'


const CustomHelmet = ({children, metaTags, title}) => {
    
    return (
        <Helmet
                htmlAttributes={{
                    lang: "en-US",
                    "data-reactroot": ""
                }}
            >
            {children}
            {metaTags && metaTags.map((props, i) => <meta key={i} {...props} />)}
            
        </Helmet>

    )
}

CustomHelmet.propTypes = {
    children: PropTypes.element,
    metaTags: PropTypes.arrayOf(PropTypes.object)
}

export default CustomHelmet
