import React, {useState, useEffect, Fragment, useContext} from 'react'
import PropTypes from 'prop-types'
import SkeletonText from 'progressive-web-sdk/dist/components/skeleton-text'
import Link from 'progressive-web-sdk/dist/components/link'
import Icon from 'progressive-web-sdk/dist/components/icon'
import {Helmet} from 'react-helmet'
import {useLocation} from 'react-router'
import {TabletOrHigher, Mobile} from '../media-queries'
import {datalayerImpressionsView, largest, isBrowser} from '../../utils/utils'
import ProductTile from '../product-tile'
import ProductListSkeletonWithoutSidebar from '../../pages/product-list/ProductListSkeletonWithoutSidebar'
import Button from 'progressive-web-sdk/dist/components/button'
import ContentAsset from '../content-asset'
import Pagination from './pagination'
import TopContent from '../../pages/search-list/topContent'
import {GlobalStateContext} from '../../components/global-state'
import ProductTilePro from '../product-tile-pro'
import {Link as WHSLink} from 'react-router-dom'

const PrimaryContent = (props) => {
    const {
        perPageItemsValue,
        category,
        categoryContentAssetId,
        categoryContentAssetId2,
        clearAllRefine,
        currentValue,
        errorMessage,
        handleRefineRemove,
        isSearchListing,
        loaded,
        masterDataArray,
        productData,
        productsPerPageOptions,
        queryCategory,
        queryCategoryName,
        queryString,
        refinedItems,
        refinements,
        selectedSortedValue,
        showCharts,
        showSideBar,
        sortingOptions,
        pageDataPushed,
        setLoading,
        totalProducts,
        fetchLazyContent,
        goToCanonicalLink,
        showWarningMessage,
        showSkeleton,
        pageNumber,
        showViewMore,
        apiTimedOut,
        handleTimeoutSkeleton,
        goToHref
    } = props
    const location = useLocation()
    const [showTabletAndHigher, setShowTabelAndHigher] = useState(false)
    const [isImpressionsViewPushed, setIsImpressionsViewPushed] = useState(false)
    const [perPage, setPerPage] = useState()
    const [sortBy, setSortBy] = useState()
    const [searchQuery, setSearchQuery] = useState()

    if (isBrowser()) {
        if (window.innerWidth >= 1024 && !showTabletAndHigher) {
            setShowTabelAndHigher(true)
        }
    }

    let query = ''
    if (location.search) {
        query = new URLSearchParams(location.search)
        query.delete('p')
        query.delete('retry')
    }
    const globalState = useContext(GlobalStateContext)
    const pageNumberTotal = Math.min(
        ...Object.keys(
            globalState?.categoryProductData?.[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ] || {}
        )
    )
    const baseUrl = 'https://www.whsmith.co.uk'
    const pageUrl = baseUrl + encodeURI(location.pathname)

    const getURL = (c_h1Tag) => {
        if (c_h1Tag) {
            let urlStr = c_h1Tag.replace(/%|\./g, '').toLowerCase()

            return urlStr
        }
    }

    useEffect(() => {
        if (productData?.length > 0 && masterDataArray?.length > 0) {
            if (!isImpressionsViewPushed && pageDataPushed) {
                const list =
                    isSearchListing && typeof queryString !== 'undefined'
                        ? 'searchResult-page'
                        : category?.name
                const pageType =
                    isSearchListing && typeof queryString !== 'undefined'
                        ? 'search-result-page'
                        : ''
                setTimeout(() => {
                    datalayerImpressionsView(
                        true,
                        masterDataArray,
                        perPageItemsValue,
                        selectedSortedValue,
                        refinedItems,
                        category,
                        list,
                        pageType
                    )
                }, 5000)
                setIsImpressionsViewPushed(true)
                setPerPage(perPageItemsValue)
                setSortBy(selectedSortedValue)
                setSearchQuery(queryString)
            }
        }
    }, [productData, masterDataArray, isImpressionsViewPushed, pageDataPushed])

    useEffect(() => {
        if (refinedItems && refinedItems?.length > 0) {
            setIsImpressionsViewPushed(false)
        }
    }, [refinedItems])

    useEffect(() => {
        if (typeof searchQuery !== 'undefined' && searchQuery !== queryString) {
            setIsImpressionsViewPushed(false)
        }
    }, [queryString])

    useEffect(() => {
        if (typeof perPage !== 'undefined' && perPage !== perPageItemsValue) {
            setIsImpressionsViewPushed(false)
        }
    }, [perPageItemsValue])

    useEffect(() => {
        if (typeof sortBy !== 'undefined' && sortBy !== selectedSortedValue) {
            setIsImpressionsViewPushed(false)
        }
    }, [selectedSortedValue])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const scrollToTopTimer = setInterval(() => {
                if (
                    document.getElementById('scrollToTop')?.offsetWidth &&
                    document.getElementById('refine-by')?.offsetWidth
                ) {
                    positionElementsDynamically()
                    clearInterval(scrollToTopTimer)
                }
            }, 1000)

            window.addEventListener('resize', positionElementsDynamically)
        }
        return () => {
            window.removeEventListener('resize', positionElementsDynamically)
        }
    }, [])

    const positionElementsDynamically = () => {
        try {
            let outerMostContainer = document.getElementsByClassName(
                't-product-list__container-items'
            )[0]
            let refinementsWrapper = document.getElementsByClassName('leftSideBar')[0]

            let scrollToTopButton = document.getElementById('scrollToTop')
            let refineBySection = document.getElementById('refine-by')

            // Align Scroll To Top Button
            if (scrollToTopButton && outerMostContainer && window.innerWidth > 1023) {
                scrollToTopButton.style.setProperty(
                    'left',
                    `${outerMostContainer.offsetLeft +
                        outerMostContainer.offsetWidth -
                        scrollToTopButton.offsetWidth -
                        (window.innerWidth < 1364 ? 25 : 70)}px`
                )
            }

            // Align Left Sidebar Refine By Section
            if (refineBySection && refinementsWrapper) {
                if (refineBySection.style.position == 'fixed') {
                    refineBySection.style.setProperty('left', `${refinementsWrapper.offsetLeft}px`)
                    refineBySection.style.setProperty(
                        'width',
                        `${refinementsWrapper.offsetWidth}px`
                    )
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    function groupMaker(values, rowSize) {
        let result = []
        for (let i = 0; i < values.length; i += rowSize) {
            result.push(values.slice(i, i + rowSize))
        }
        return result
    }

    function scrollHandler() {
        if (isBrowser()) {
            let listOfClasses = ['availability-msg', 'product-tile-info']
            let count = 0
            listOfClasses.forEach((className) => {
                const listOfElements = [...document.getElementsByClassName(className)]
                const values = listOfElements.map((ele) => ele.offsetHeight)
                const groupedElements = groupMaker(
                    listOfElements,
                    isBrowser() ? (window.innerWidth <= 767 ? 2 : 3) : listOfElements.length
                )
                const groupedValues = groupMaker(
                    values,
                    isBrowser() ? (window.innerWidth <= 767 ? 2 : 3) : listOfElements.length
                )
                if (
                    groupedValues?.length &&
                    !groupedValues.every((list) => list.every((value) => value == list[0]))
                ) {
                    const requiredHeight = groupedValues.map((list) => largest(list))
                    groupedElements.forEach((element, index) => {
                        element.forEach((ele) => {
                            ele.style.minHeight = `${requiredHeight[index]}px`
                        })
                    })
                } else if (
                    groupedValues?.length &&
                    groupedValues.every((list) => list.every((value) => value == list[0])) &&
                    totalProducts < 12
                        ? values?.length === totalProducts
                        : values?.length === perPageItemsValue
                ) {
                    count++
                    if (count == listOfClasses.length) {
                        document.removeEventListener('scroll', scrollHandler)
                    }
                }
            })
        }
    }

    useEffect(() => {
        if ((perPageItemsValue || totalProducts) && loaded && !showCharts) {
            scrollHandler()
            document.addEventListener('scroll', scrollHandler)
        }
        return () => {
            document.removeEventListener('scroll', scrollHandler)
        }
    })

    const handleClickOutside = (event) => {
        if (checkIfClassExists(event.target, 'overlay') >= 0) {
            showSideBar(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        positionElementsDynamically()
    })

    const checkIfClassExists = (el, className) => {
        return (' ' + el.className + ' ').replace(/[\n\t]/g, ' ').indexOf(` ${className} `)
    }

    const setRefineBadges = () => {
        let data = []
        let priceIndex = refinements.findIndex((item) => item.attribute_id == 'price')
        refinements.forEach((item, index) => {
            data[index] = refinedItems
                .filter((data) => data.id == item.attribute_id && data.include == true)
                .map((result, i) => {
                    return (
                        <span
                            className="filter-selection"
                            data-divider=""
                            data-selected-filter-id="Product Type"
                            key={`span_${result}_${i}`}
                        >
                            <span className="filter-selection-name">{result.type}: </span>
                            <span className="filter-selection-value">
                                {result.label}
                                <Link
                                    className="filter-selection-delete"
                                    href="#foo"
                                    data-value={result.name}
                                    onClick={(event) => handleRefineRemove(event)}
                                    title="Remove"
                                >
                                    <svg className="icon-close">
                                        <Icon name="close" title="Close" size="small" />
                                    </svg>
                                </Link>
                            </span>
                        </span>
                    )
                })
        })
        if (priceIndex > -1) {
            let temp = data[priceIndex]
            data[priceIndex] = data[0]
            data[0] = temp
        }
        return data
    }

    return (
        <div id="primary" className="primary-content is-reverse">
            <Helmet
                htmlAttributes={{
                    lang: 'en-US',
                    'data-reactroot': ''
                }}
            >
                <script type="application/ld+json">
                    {`
                {
                    "@context": "https://schema.org",
                    "@graph": [
                    {
                        "@type": "WebPage",
                        "@id": "${pageUrl}#webpage",
                        "url": "${pageUrl}",
                        "name": "${category && category.c_h1Tag ? category.c_h1Tag : ''}",
                        "isPartOf": {
                        "@id": "${baseUrl}/#website"
                        },
                        "about": {
                        "@id": "${baseUrl}/#corporation"
                        },
                        "datePublished": "",
                        "dateModified": "",
                        "inLanguage": "en-GB"
                    },
                    {
                        "@context": "https://schema.org",
                        "@type": "ItemList",
                        "@id": "${pageUrl}#listitem",
                        "name": "${
                            category && category.c_h1Tag
                                ? category.c_h1Tag
                                : 'Books, stationery, gifts and much more'
                        }",
                        "itemListElement": ${JSON.stringify(
                            productData?.map((product, index) => {
                                let productRouteUrl = `${baseUrl}/${getURL(product?.c_page_url)}/${
                                    product?.master?.master_id
                                }.html`
                                return {
                                    '@type': 'ListItem',
                                    position: index + 1,
                                    url: `${productRouteUrl}`,
                                    image:
                                        typeof product.c_images !== 'undefined'
                                            ? product.c_images[0]['url']
                                            : ''
                                }
                            })
                        )}
                    }
                    ]
                }
                `}
                </script>
            </Helmet>
            {isSearchListing ? (
                <TopContent
                    categories={category}
                    selectedCategory={queryCategoryName}
                    selectedCategoryId={queryCategory}
                    goToHref={goToHref}
                    categoryName={category.name}
                    queryString={queryString}
                    setLoading={setLoading}
                />
            ) : (
                <Fragment>
                    {category ? (
                        <h1 className="plp-title">{category.c_h1Tag}</h1>
                    ) : (
                        <SkeletonText type="h1" width="50%" />
                    )}
                    <ContentAsset
                        contentAssetId={categoryContentAssetId}
                        cssClass={'plp-description-ca'}
                    />
                </Fragment>
            )}
            <TabletOrHigher>
                {productData?.length > 0 && (
                    <Pagination
                        sortingOptions={sortingOptions}
                        currentValue={currentValue}
                        showWarningMessage={showWarningMessage}
                        goToCanonicalLink={goToCanonicalLink}
                        productsPerPageOptions={productsPerPageOptions}
                        showSideBar={() => showSideBar(true)}
                        pageNumber={pageNumber}
                        selectedSortedValue={selectedSortedValue}
                        perPageItemsValue={perPageItemsValue}
                        refinedItems={refinedItems}
                        showCharts={showCharts}
                        setLoading={setLoading}
                        totalProducts={totalProducts}
                    />
                )}
            </TabletOrHigher>
            <Mobile>
                {productData?.length > 0 && (
                    <Pagination
                        showWarningMessage={showWarningMessage}
                        goToCanonicalLink={goToCanonicalLink}
                        sortingOptions={sortingOptions}
                        currentValue={currentValue}
                        productsPerPageOptions={productsPerPageOptions}
                        showSideBar={() => showSideBar(true)}
                        pageNumber={pageNumber}
                        disablePaginationOnMobile={true}
                        selectedSortedValue={selectedSortedValue}
                        perPageItemsValue={perPageItemsValue}
                        refinedItems={refinedItems}
                        showCharts={showCharts}
                        setLoading={setLoading}
                        totalProducts={totalProducts}
                    />
                )}
            </Mobile>
            <div className="t-product-list__container">
                {errorMessage && (
                    <h1 className="u-margin-top-lg u-margin-center t-product-list__error-msg">
                        {errorMessage}
                    </h1>
                )}
                <TabletOrHigher>
                    {refinements &&
                        refinements.length > 0 &&
                        refinedItems.filter((item) => item.include == true && item.id !== 's')
                            .length > 0 && (
                            <div className="applied-filter-wrapper">
                                <div className="filter-applied">
                                    <span className="filter-refined-by">Refined by</span>
                                    {setRefineBadges()}
                                    <Link
                                        className="filter-selection-clear"
                                        href="#foo"
                                        onClick={(event) => clearAllRefine(event)}
                                    >
                                        Clear all{' '}
                                    </Link>
                                </div>
                            </div>
                        )}
                </TabletOrHigher>
                <div className="t-product-list__container-items">
                    {typeof productData !== 'undefined' &&
                    loaded &&
                    masterDataArray?.length === productData?.length &&
                    totalProducts ? (
                        <>
                            {productData?.map((product, index) =>
                                product !== null && typeof product !== 'undefined' ? (
                                    <>
                                        <div
                                            className={
                                                showCharts
                                                    ? 'grid-tile'
                                                    : `t-product-list__products-items ${
                                                          (index + 1) % 2 == 0
                                                              ? 'margin-left'
                                                              : 'margin-right'
                                                      }`
                                            }
                                            key={product.id}
                                        >
                                            <TabletOrHigher>
                                                {!showCharts && masterDataArray[index] && (
                                                    <ProductTilePro
                                                        product={product}
                                                        masterProduct={masterDataArray[index]}
                                                        cssClass={showCharts ? 'product-chart' : ''}
                                                        plpCssClass={showCharts ? 'plp' : ''}
                                                        position={index + 1}
                                                        queryString={queryString}
                                                        category={category}
                                                        categoryData={category}
                                                        categoryName={category.name}
                                                        showOnLazyLoad={true}
                                                        isSearchListing={isSearchListing}
                                                    />
                                                )}
                                                {showCharts && masterDataArray[index] && (
                                                    <ProductTile
                                                        dataId={product.id}
                                                        product={product}
                                                        masterProduct={masterDataArray[index]}
                                                        cssClass="product-chart"
                                                        chartNumber={
                                                            parseInt(
                                                                pageNumberTotal === 'Infinity'
                                                                    ? 0
                                                                    : (pageNumberTotal || 0) + 1
                                                            ) *
                                                                parseInt(
                                                                    process.env
                                                                        .PRODUCT_SEARCH_COUNT || 12
                                                                ) +
                                                            (index + 1)
                                                        }
                                                        position={index + 1}
                                                        queryString={queryString}
                                                        categoryData={category}
                                                        categoryName={category.name}
                                                        showOnLazyLoad={true}
                                                        isFaceout={false}
                                                    />
                                                )}
                                            </TabletOrHigher>
                                            <Mobile>
                                                {!showCharts && masterDataArray[index] && (
                                                    <ProductTile
                                                        product={product}
                                                        masterProduct={masterDataArray[index]}
                                                        cssClass="product-chart"
                                                        plpCssClass="plp"
                                                        position={index + 1}
                                                        queryString={queryString}
                                                        categoryData={category}
                                                        categoryName={category.name}
                                                        showOnLazyLoad={true}
                                                        isFaceout={false}
                                                    />
                                                )}
                                                {showCharts && masterDataArray[index] && (
                                                    <ProductTile
                                                        dataId={product.id}
                                                        product={product}
                                                        masterProduct={masterDataArray[index]}
                                                        cssClass="product-chart"
                                                        plpCssClass="plp"
                                                        chartNumber={
                                                            parseInt(
                                                                pageNumberTotal === 'Infinity'
                                                                    ? 0
                                                                    : (pageNumberTotal || 0) + 1
                                                            ) *
                                                                parseInt(
                                                                    process.env
                                                                        .PRODUCT_SEARCH_COUNT || 12
                                                                ) +
                                                            (index + 1)
                                                        }
                                                        position={index + 1}
                                                        queryString={queryString}
                                                        categoryData={category}
                                                        categoryName={category.name}
                                                        showOnLazyLoad={true}
                                                        isFaceout={false}
                                                    />
                                                )}
                                            </Mobile>
                                            <meta itemProp="productID" content={product.id} />
                                            <meta
                                                itemProp="url"
                                                content={`/products/${product.id}`}
                                            />
                                        </div>
                                        {/* Mobile Devices PLP and SLP Pages */}
                                        {!showCharts &&
                                            index !== productData?.length - 1 &&
                                            (index + 1) % 2 == 0 &&
                                            typeof window !== 'undefined' &&
                                            window.innerWidth <= 767 && (
                                                <hr
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        marginBottom: '30px'
                                                    }}
                                                />
                                            )}

                                        {/* Mobile Devices PLP and SLP Pages Last Line */}
                                        {!showCharts &&
                                            index === productData?.length - 1 &&
                                            typeof window !== 'undefined' &&
                                            window.innerWidth <= 767 && (
                                                <hr
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        marginBottom: '30px'
                                                    }}
                                                />
                                            )}

                                        {/* Tablet and Higher Devices PLP and SLP Pages */}
                                        {!showCharts &&
                                            index !== productData?.length - 1 &&
                                            (index + 1) % 3 == 0 &&
                                            typeof window !== 'undefined' &&
                                            window.innerWidth >= 768 && (
                                                <hr
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        marginBottom: '30px'
                                                    }}
                                                />
                                            )}

                                        {/* Tablet and Higher Devices PLP and SLP Pages Last Line */}
                                        {!showCharts &&
                                            index === productData?.length - 1 &&
                                            typeof window !== 'undefined' &&
                                            window.innerWidth >= 768 && (
                                                <hr
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '30px',
                                                        marginBottom: '30px'
                                                    }}
                                                />
                                            )}

                                        {/* Charts Listing Pages */}
                                        {showCharts && index !== productData?.length - 1 && (
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    marginTop: '0',
                                                    marginBottom: '30px'
                                                }}
                                            />
                                        )}

                                        {/* Charts Listing Pages Last Line*/}
                                        {showCharts && index === productData?.length - 1 && (
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    marginTop: '0',
                                                    marginBottom: '30px'
                                                }}
                                            />
                                        )}
                                    </>
                                ) : null
                            )}
                            {showSkeleton ? (
                                <>
                                    <ProductListSkeletonWithoutSidebar
                                        list={[
                                            ...new Array(
                                                parseInt(process.env.PRODUCT_SEARCH_COUNT || 12)
                                            )
                                        ]}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        <span className="results-total-data">
                                            Viewed {productData?.length} of{' '}
                                            {parseInt(totalProducts)} Results
                                        </span>
                                    </div>
                                    <div className="button-classname">
                                        <div>
                                            {!showViewMore ? null : (
                                                <WHSLink
                                                    replace
                                                    to={
                                                        !apiTimedOut
                                                            ? showSkeleton
                                                                ? ''
                                                                : fetchLazyContent(
                                                                      Math.ceil(
                                                                          parseInt(pageNumber) + 1
                                                                      )
                                                                  )
                                                            : fetchLazyContent(pageNumber, true)
                                                    }
                                                    onClick={(e) => {
                                                        if (showSkeleton) {
                                                            e.preventDefault()
                                                        }
                                                        if (apiTimedOut) {
                                                            handleTimeoutSkeleton()
                                                        }
                                                    }}
                                                >
                                                    <Button
                                                        type="button"
                                                        className={
                                                            apiTimedOut
                                                                ? 'pw--tertiary-retry-button'
                                                                : 'pw--tertiary-view-more'
                                                        }
                                                        id="viewMore"
                                                        {...(!apiTimedOut
                                                            ? {disabled: !showViewMore}
                                                            : {})}
                                                        aria-label="cancel"
                                                    >
                                                        <div className="button-text-style">
                                                            {apiTimedOut
                                                                ? 'Oops, Something went wrong. Please click to try again.'
                                                                : 'VIEW MORE'}
                                                        </div>
                                                    </Button>
                                                </WHSLink>
                                            )}
                                        </div>
                                        {productData?.length > process.env.PRODUCT_SEARCH_COUNT ? (
                                            <Button
                                                className="pw--tertiary-view-more"
                                                id="scrollToTop"
                                                onClick={() => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                }}
                                            >
                                                <Icon
                                                    name="chevron-up"
                                                    className="scroll-to-top-icon"
                                                />
                                            </Button>
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        <span className="results-total-data">
                                            Viewed {productData?.length} of{' '}
                                            {parseInt(totalProducts)} Results
                                        </span>
                                    </div>
                                    <div className="button-classname">
                                        <div>
                                            {!showViewMore ? null : (
                                                <WHSLink
                                                    replace
                                                    to={
                                                        !apiTimedOut
                                                            ? showSkeleton
                                                                ? ''
                                                                : fetchLazyContent(
                                                                      Math.ceil(
                                                                          parseInt(pageNumber) + 1
                                                                      )
                                                                  )
                                                            : fetchLazyContent(pageNumber, true)
                                                    }
                                                    onClick={(e) => {
                                                        if (showSkeleton) {
                                                            e.preventDefault()
                                                        }
                                                        if (apiTimedOut) {
                                                            handleTimeoutSkeleton()
                                                        }
                                                    }}
                                                >
                                                    <Button
                                                        type="button"
                                                        className={
                                                            apiTimedOut
                                                                ? 'pw--tertiary-retry-button'
                                                                : 'pw--tertiary-view-more'
                                                        }
                                                        id="viewMore"
                                                        {...(!apiTimedOut
                                                            ? {disabled: !showViewMore}
                                                            : {})}
                                                        aria-label="cancel"
                                                    >
                                                        <div className="button-text-style">
                                                            {apiTimedOut
                                                                ? 'Oops, Something went wrong. Please click to try again.'
                                                                : 'VIEW MORE'}
                                                        </div>
                                                    </Button>
                                                </WHSLink>
                                            )}
                                        </div>
                                        {productData?.length > process.env.PRODUCT_SEARCH_COUNT ? (
                                            <Button
                                                className="pw--tertiary-view-more"
                                                id="scrollToTop"
                                                onClick={() => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                }}
                                            >
                                                <Icon
                                                    name="chevron-up"
                                                    className="scroll-to-top-icon"
                                                />
                                            </Button>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <ProductListSkeletonWithoutSidebar
                            list={[...new Array(parseInt(process.env.PRODUCT_SEARCH_COUNT || 12))]}
                        />
                    )}
                </div>
            </div>
            {!isSearchListing && (
                <ContentAsset
                    contentAssetId={categoryContentAssetId2}
                    cssClass={'plp-description-ca'}
                />
            )}
        </div>
    )
}

PrimaryContent.propTypes = {
    category: PropTypes.object,
    categoryContentAssetId: PropTypes.string,
    categoryContentAssetId2: PropTypes.string,
    clearAllRefine: PropTypes.func,
    currentValue: PropTypes.any,
    errorMessage: PropTypes.string,
    handleRefineRemove: PropTypes.func,
    isSearchListing: PropTypes.bool,
    loaded: PropTypes.bool,
    masterDataArray: PropTypes.array,
    productData: PropTypes.array,
    productsPerPageOptions: PropTypes.array,
    queryCategory: PropTypes.string,
    queryCategoryName: PropTypes.string,
    queryString: PropTypes.string,
    refinedItems: PropTypes.array,
    refinements: PropTypes.any,
    selectedSortedValue: PropTypes.string,
    showCharts: PropTypes.any,
    showSideBar: PropTypes.func,
    sortingOptions: PropTypes.any,
    pageDataPushed: PropTypes.any
}

export default PrimaryContent
