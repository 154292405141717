import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Link from 'progressive-web-sdk/dist/components/link'
import {Desktop} from '../../components/media-queries'

const CategoriesBar = ({categories: {categories}, searchPhrase}) => {
    const [cats, setCats] = useState([])

    useEffect(() => {
        if (categories && categories.length > 0)
            setCats(
                categories.reduce((result, category) => {
                    category.c_showInMenu && result.push(category)
                    return result
                }, [])
            )
    }, [categories])

    return (
        <div className="refinement expanded category-refinement" data-refinement-id="Category">
            <Desktop>
                <h3 className="toggle-icon refinement-title is-desktop js-toggle">Category</h3>
            </Desktop>
            <div className="accordion-content">
                <ul
                    id="category-level-1"
                    className="category-level-1 js-collapsible category-level-list expanded"
                    data-cmp="readMore"
                    data-cnt-visible="10"
                >
                    {cats &&
                        cats.length > 0 &&
                        cats.map((category) => (
                            <li className="refinement-item expandable" key={`list-${category.id}`}>
                                <Link 
                                    className="refinement-link-category"
                                    title={`Go to Category: ${category.id.toUpperCase()}`}
                                    rel="nofollow"
                                    href={`/search/?${(new URLSearchParams(`q=${searchPhrase}&cgid=${category.id.toUpperCase()}&category=${
                                        category.name
                                    }`)).toString()}`}
                                    // href={`/${category.name.toLowerCase()}/?q=${searchPhrase}`}
                                >
                                   <h3>{category.name} </h3> 
                                </Link>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    )
}

CategoriesBar.propTypes = {
    searchPhrase: PropTypes.string,
    categories: PropTypes.array
}

export default CategoriesBar
