import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Desktop, TabletOrSmaller} from '../media-queries'
import Select from '../../pages/select'
import SelectSort from '../../pages/sort-select'

const ProductsSortLimit = (props) => {
    const {sortingOptions, currentValue, selectedSortedValue, showCharts} = props
    const [opened, setOpened] = useState(false)
    return (
        <>
            <Desktop>
                <div className="ProductsSortLimit__search-result-options ProductsSortLimit__top ">
                    <div className="ProductsSortLimit__results-hits"></div>
                    <div className="ProductsSortLimit__pagination"></div>
                    {!showCharts && (
                        <div className="ProductsSortLimit__search-result-navigation">
                            <div className="ProductsSortLimit__refinements-dialog ProductsSortLimit__is-desktop"></div>
                            <div className="ProductsSortLimit__page-control">
                                <div className="ProductsSortLimit__sort-by">
                                    {!showCharts &&
                                    sortingOptions?.length &&
                                    selectedSortedValue ? (
                                        <div
                                            className={`ProductsSortLimit__sort-by-content ${
                                                opened ? 'sort-open' : ''
                                            }`}
                                        >
                                            <label
                                                htmlFor="sortby"
                                                className="ProductsSortLimit__page-control-label"
                                            >
                                                Sort by:
                                            </label>
                                            <span className="ProductsSortLimit__select-span">
                                                <Select
                                                    addButtonIcon
                                                    currentValue={currentValue}
                                                    disableIcon
                                                    id="sort-select"
                                                    options={sortingOptions}
                                                    setOption={selectedSortedValue}
                                                    onOpen={() => setOpened(true)}
                                                    onClose={() => setOpened(false)}
                                                />
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Desktop>
            <TabletOrSmaller>
                {!showCharts && (
                    // <div
                    //     className="ProductsSortLimit__sort-by select-sort"
                    //     style={{backgroundColor: opened ? 'white' : '#cfe9dc'}}
                    //     onClick={(event) => {
                    //         setOpened(!opened)
                    //     }}
                    // >
                    // <fieldset>
                    //     <span className={'ProductsSortLimit__select-span'}>
                    <>
                        {!showCharts && sortingOptions?.length && selectedSortedValue ? (
                            <SelectSort
                                addButtonIcon
                                opened={opened}
                                setOpened={setOpened}
                                currentValue={currentValue}
                                disableIcon
                                id="sort-select"
                                options={sortingOptions}
                                setOption={selectedSortedValue}
                            />
                        ) : null}
                    </>
                    //     </span>
                    // </fieldset>
                    // </div>
                )}
            </TabletOrSmaller>
        </>
    )
}

ProductsSortLimit.propTypes = {
    sortingOptions: PropTypes.array,
    currentValue: PropTypes.func
}

export default ProductsSortLimit
