import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {useLocation} from 'react-router-dom'
// import Link from 'progressive-web-sdk/dist/components/link'

import {Desktop, TabletOrSmaller, TabletOrHigher} from '../media-queries'
import ProductsSortLimit from '../products-sort-limit'
import {GlobalStateContext} from '../../components/global-state'
// import {GoToFirstPage, GoToLastPage, GoToNextPage, GoToPrevPage} from './paginationIcons'
import {Link as WHSLink} from 'react-router-dom'

const Pagination = ({
    sortingOptions,
    currentValue,
    productsPerPageOptions,
    showSideBar,
    disableNav,
    disableSearchOptions,
    selectedSortedValue,
    refinedItems,
    showCharts,
    totalProducts,
    showWarningMessage,
    goToCanonicalLink,
    setLoading
}) => {
    let query = ''
    const location = useLocation()
    if (location.search) {
        query = new URLSearchParams(location.search)
        query.delete('p')
        query.delete('retry')
    }
    const globalState = useContext(GlobalStateContext)
    const pageNumber = Math.min(
        ...Object.keys(
            globalState?.categoryProductData?.[
                `${location.pathname}${query.toString() && '?' + query.toString()}`
            ] || {}
        )
    )

    const showMiddleWarning = () => {
        return (
            <div className="middle-warning-message">
                <Desktop>
                    <span style={{marginTop: 20, marginBottom: 40}}>
                        You&apos;ve arrived in the middle of a list! You can scroll as normal
                        or&nbsp;
                        <WHSLink
                            replace
                            className="middle-warning-browse"
                            to={goToCanonicalLink()}
                            onClick={() => {
                                setLoading(true)
                            }}
                        >
                            browse from the start
                        </WHSLink>
                    </span>
                </Desktop>
                <TabletOrSmaller>
                    <span>
                        You&apos;ve arrived in the middle of a list! You can scroll as normal
                        or&nbsp;
                        <WHSLink
                            replace
                            className="middle-warning-browse"
                            to={goToCanonicalLink()}
                            onClick={() => {
                                setLoading(true)
                            }}
                        >
                            browse from the start
                        </WHSLink>
                    </span>
                </TabletOrSmaller>
            </div>
        )
    }

    return (
        <div>
            <div className="search-result-options top" style={{marginTop: '25px'}}>
                <TabletOrSmaller>
                    {!disableNav && (
                        <div
                            className="ProductsSortLimit__sort-by"
                            onClick={(event) => {
                                event.preventDefault()
                                showSideBar(true)
                            }}
                        >
                            <fieldset>
                                <span className="ProductsSortLimit__select-span">
                                    <div className="js-refinements-dialog-open">
                                        {showCharts ? 'Other charts ' : 'FILTERS'}
                                        {refinedItems.filter(
                                            (item) => item.include == true && item.id !== 's'
                                        ).length > 0 &&
                                            `(${
                                                refinedItems.filter(
                                                    (item) =>
                                                        item.include == true && item.id !== 's'
                                                ).length
                                            })`}
                                    </div>
                                </span>
                            </fieldset>
                        </div>
                    )}
                </TabletOrSmaller>
                <Desktop>
                    {totalProducts && !isNaN(totalProducts) ? (
                        <div>
                            {/* <span className="results-diapason">{`${resultStart} - ${resultEnd}`}</span> */}
                            <span className="results-total">{parseInt(totalProducts)} Results</span>
                        </div>
                    ) : null}
                </Desktop>
                {!disableSearchOptions && (
                    <ProductsSortLimit
                        sortingOptions={sortingOptions}
                        currentValue={(value) => {
                            if (value !== '' && value !== selectedSortedValue) {
                                currentValue(value)
                            }
                        }}
                        productsPerPageOptions={productsPerPageOptions}
                        selectedSortedValue={selectedSortedValue}
                        showCharts={showCharts}
                    />
                )}
            </div>
            {showWarningMessage && showMiddleWarning()}
            <TabletOrSmaller>
                {totalProducts && !isNaN(totalProducts) ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <span className="results-total-data">{totalProducts} Results</span>
                    </div>
                ) : null}
            </TabletOrSmaller>
        </div>
    )
}

Pagination.propTypes = {
    sortingOptions: PropTypes.any,
    currentValue: PropTypes.func,
    productsPerPageOptions: PropTypes.array,
    showSideBar: PropTypes.func,
    disableNav: PropTypes.bool,
    disableSearchOptions: PropTypes.bool,
    onPageNumberChange: PropTypes.func,
    pageNumber: PropTypes.number,
    disablePaginationOnMobile: PropTypes.bool,
    selectedSortedValue: PropTypes.string,
    refinedItems: PropTypes.array
}

export default Pagination
